/* eslint-disable max-len */
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    ButtonBase,
    Typography,
    makeStyles,
    Dialog,
    Button,
    Drawer,
} from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import { eventMetricsMap, useEventsReporter, useRouter } from 'src/hooks';
import moment from 'moment';
import StoreDetailsDrawer from 'src/components/storeDetailsDrawer';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingOverlay from 'src/components/loading-overlay';
import StoreSearchModal from 'src/components/store-search-modal';
import useStoreData from 'src/hooks/useStoreData';
import { Store } from 'src/lib/api-client/types';
import { Body, Heading } from 'src/components/UI';
import { isListEnabled } from 'src/lib/api-client/lists';
import { useQuery } from 'react-query';
import ShippingInfoContext from '../../../lib/contexts/shipping-info-context';

const useStyles = makeStyles((theme) => ({
    shipmentInfoConatiner: {
        display: 'flex',
        flexDirection: 'column',
    },
    shipmentTab: {
        height: '40px',
        borderRadius: '300px',
        background: '#FFFFFF',
        display: 'flex',
    },
    activeTab: {
        background: '#CC0000',
        borderRadius: '20px',
        color: '#FFFFFF',
        width: '50%',
        fontWeight: 500,
    },
    tabAlign: {
        background: '#FFFFFF',
        borderRadius: '20px',
        color: '#101820',
        width: '50%',
        fontWeight: 500,
    },
    mainConatiner: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
    },
    headerText: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '0px',
        maxWidth: 327,
        marginBottom: '10px',
    },
    headerSubText: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        marginBottom: '30px',
        color: '#086DD2',
        display: 'flex',
        gap: '6px',
    },
    shipmentInfoRateConatiner: {
        borderRadius: '10px',
        marginTop: '24px',
    },
    shipmentInfoRate: {
        display: 'flex',
        flexDirection: 'column',
        height: '90px',
        padding: '24px',
        justifyContent: 'center',
        // '&:nth-child(even)': {background: '#F2F2F2'},
        '&:nth-child(odd)': { background: '#FFFFFF' },
        '&:first-child': {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
        },
        '&:last-child': {
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
    },
    shipmentInfoRateAndType: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    shipmentInfoDeliveryAndDate: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
    },
    serviceNameRate: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    deliveryDate: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    btn: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        padding: '18px 22px',
        width: 'calc(100% + 48px)',
        marginLeft: '-24px',
        bottom: '0',
        background: '#F2F2F2',
    },
    shadow: {
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)',
    },
    btnContainerHome: {
        display: 'flex',
        flexDirection: 'column',
    },
    backToHome: {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0px',
        color: '#086DD2',
        marginTop: '24px',
    },
    paper: {
        background: '#ffffff',
        borderRadius: '15px',
        padding: '10px 25px 40px 25px ',
        flexDirection: 'column',
        alignItems: 'center',
    },
    container: {
        margin: '0 0 16px',
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '16px',
        },
    },
    location: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginBottom: '15px',
    },
    locationText: {
        fontSize: '26px',
        fontWeight: 700,
        lineHeight: '36px',
        color: '#000000',
    },
    ratecalculateText: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#030100',
        marginBottom: '16px',
    },
    shipFromText: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#000000',
    },
    addressContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    shipDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    shipDataAlign: {
        display: 'flex',
    },
    staplesStoreNumber: {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        color: '#086DD2',
    },
    storeAddresss: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#000000',
    },
    storePhoneAlign: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#086DD2',
        margin: '10px 0px 24px',
    },
    shipLabelAlign: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#000000',
        width: '50%',
    },
    shipResDataAlign: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
        color: '#000000',
        width: '50%',
    },
    closeIcon: {
        justifyContent: 'flex-end',
        paddingRight: '0px',
    },
    link: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        color: '#0192DB',
        marginBottom: '20px',
        cursor: 'pointer',
    },
    holidayDisAlign: {
        background: '#CC0000',
        borderRadius: '15px 15px 0px 0px',
        height: '34px',
        display: 'flex',
        alignItems: 'center',
        color: '#ffffff',
        width: '100%',
        padding: '0px 16px',
        marginBottom: '-24px',
        marginTop: '16px',
    },
    shipmentInfoRateHoliday: {
        display: 'flex',
        flexDirection: 'column',
        height: '90px',
        padding: '24px',
        justifyContent: 'center',
        '& last-of-type': {
            borderBottom: '2px solid #CC0000',
        },
        '&:nth-child(odd)': { background: '#FFFFFF' },
        borderRight: '2px solid #cc0000',
        borderLeft: '2px solid #cc0000',
    },
}));

const cutoffDate = new Date('12/24/2024');

const ShippingRateDelivery = ({
    shipmentRateInfo,
    initialShipmentValues,
    bottomShadow,
    HolidayShippingEnabled,
}: {
    shipmentRateInfo: any;
    initialShipmentValues: any;
    bottomShadow: boolean;
    HolidayShippingEnabled: any;
}) => {
    const [tab, setTab] = useState(
        HolidayShippingEnabled?.isenabled ? 'fastest' : 'cheapest'
    );
    const [openModal, setOpenModal] = useState(false);
    const [shipmentRate, setShipmentRate] = useState([]);
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const { updateFetchSenderInfo } = useContext(ShippingInfoContext);
    const [isLoading, setIsLoading] = useState(false);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [storeDetailsModalOpen, setStoreDetailsModalOpen] = useState(false);
    const { mainStore, additionalStores, updateMainStore } = useStoreData();
    const [
        shipmentRateInfoBelowCutOffDate,
        setShipmentRateInfoBelowCutOffDate,
    ] = useState([]);
    const [
        shipmentRateInfoAboveCutOffDate,
        setShipmentRateInfoAboveCutOffDate,
    ] = useState([]);

    const onStoreSelected = (store: Store) => {
        updateMainStore(store);
        setSearchModalOpen(false);
    };
    const handleChangeLocation = () => setSearchModalOpen(true);
    const sortingData = (tabData: string) => {
        let sortDate;
        const getCopyShipmentRate = { ...shipmentRateInfo };
        if (tabData === 'cheapest') {
            sortDate = getCopyShipmentRate?.data?.[0]?.rates?.sort(
                (a: any, b: any) => a.total - b.total
            );
        } else {
            sortDate = getCopyShipmentRate?.data?.[0]?.rates?.sort(
                (a: any, b: any) => {
                    const da = new Date(a.deliveryDate).getTime();
                    const db = new Date(b.deliveryDate).getTime();
                    return da - db;
                }
            );
            if (HolidayShippingEnabled?.isenabled) {
                const filteredDataBelowCutOffData = sortDate?.filter(
                    (rate: any) => new Date(rate.deliveryDate) <= cutoffDate
                );
                const filteredDataAboveCutOffDate = sortDate?.filter(
                    (rate: any) => new Date(rate.deliveryDate) > cutoffDate
                );
                setShipmentRateInfoBelowCutOffDate(filteredDataBelowCutOffData);
                setShipmentRateInfoAboveCutOffDate(filteredDataAboveCutOffDate);
            }
        }
        return setShipmentRate(sortDate);
    };

    useEffect(() => {
        if (!shipmentRateInfo?.data?.[0]?.rates) {
            return history.push(ROUTES.shippingServices);
        }
        if (HolidayShippingEnabled?.isenabled) {
            return sortingData('fastest');
        }
        return sortingData('cheapest');
    }, []);

    const handleOnChange = (data: string) => {
        sortingData(data);
        setTab(data);
    };

    const dateFormate = (date: string) => {
        const defaultFormat = 'ddd MM/DD/YY';
        const formatedDate = moment(date).format(defaultFormat);
        return formatedDate;
    };
    const handleCloseDrawer = () => {
        setStoreDetailsModalOpen(false);
    };
    const handleOpenDrawer = () => {
        setStoreDetailsModalOpen(true);
    };

    const handleStartShipment = () => {
        updateFetchSenderInfo(true);
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=createShipmentSenderInfo',
        });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'shipping quote page',
        });
    };

    const handleNavigateHome = () => {
        history.push({
            pathname: ROUTES.home,
        });
    };

    if (!shipmentRateInfo?.data?.[0]?.rates) {
        return null;
    }

    const shipmentDetails = () => {
        if (!shipmentRateInfo?.data) {
            return false;
        }
        const { facilityViewModel }: any = shipmentRateInfo?.data?.[0];
        return (
            <>
                <Typography className={classes.ratecalculateText}>
                    Your rates are calculated on the shipping details below.
                </Typography>
                <FullWidthCard
                    aria-labelledby="reward-confirmation-screen-sign-up"
                    className={classes.container}
                >
                    <Typography className={classes.shipFromText}>
                        Shipped From
                    </Typography>
                    <div className={classes.location} id="location">
                        <Typography className={classes.locationText}>
                            {facilityViewModel?.facilityCity},{' '}
                            {facilityViewModel?.facilityState}
                        </Typography>
                    </div>
                    <div className={classes.shipDataContainer}>
                        <div className={classes.shipDataAlign}>
                            <Typography
                                className={`${classes.shipLabelAlign} ${classes.link}`}
                                onClick={handleChangeLocation}
                            >
                                Change location
                            </Typography>
                            <Typography
                                className={`${classes.shipResDataAlign}  ${classes.link}`}
                                onClick={handleOpenDrawer}
                            >
                                Store details{' '}
                            </Typography>
                        </div>
                        <div className={classes.shipDataAlign}>
                            <Typography className={classes.shipLabelAlign}>
                                Ship to
                            </Typography>
                            <Typography className={classes.shipLabelAlign}>
                                Shipment weight
                            </Typography>
                        </div>
                        <div className={classes.shipDataAlign}>
                            <Typography className={classes.shipResDataAlign}>
                                {initialShipmentValues?.recipientZipCode}
                            </Typography>
                            <Typography className={classes.shipResDataAlign}>
                                {initialShipmentValues?.weight} lb.
                            </Typography>
                        </div>
                    </div>
                </FullWidthCard>
            </>
        );
    };

    return (
        <div className={classes.mainConatiner}>
            {shipmentDetails()}
            <LoadingOverlay variant="wordmark" theme="light" open={isLoading} />
            {searchModalOpen ? (
                <StoreSearchModal
                    mainStore={mainStore}
                    nearbyStores={additionalStores}
                    onStoreSelected={onStoreSelected}
                    open={searchModalOpen}
                    ignoreIsActiveScreen
                    handleClose={() => setSearchModalOpen(false)}
                />
            ) : null}
            {storeDetailsModalOpen ? (
                <StoreDetailsDrawer
                    mainStore={mainStore}
                    nearbyStores={additionalStores}
                    onStoreSelected={onStoreSelected}
                    open={storeDetailsModalOpen}
                    ignoreIsActiveScreen
                    handleClose={() => setStoreDetailsModalOpen(false)}
                />
            ) : null}
            <Typography className={classes.headerText}>
                Review options and select your rate when you process your
                shipment.
            </Typography>
            <div className={classes.shipmentInfoConatiner}>
                <div className={classes.shipmentTab}>
                    <ButtonBase
                        className={
                            tab === 'cheapest'
                                ? classes.activeTab
                                : classes.tabAlign
                        }
                        onClick={() => handleOnChange('cheapest')}
                        value={tab}
                    >
                        Lowest prices
                    </ButtonBase>
                    <ButtonBase
                        className={
                            tab === 'fastest'
                                ? classes.activeTab
                                : classes.tabAlign
                        }
                        onClick={() => handleOnChange('fastest')}
                        value={tab}
                    >
                        Fastest speeds
                    </ButtonBase>
                </div>
                <div>
                    {HolidayShippingEnabled?.isenabled &&
                    tab === 'fastest' &&
                    shipmentRateInfoBelowCutOffDate?.length ? (
                        <div>
                            <div className={classes.holidayDisAlign}>
                                <Heading
                                    type="h4"
                                    size={16}
                                    weight="normal"
                                    lineHeight={20}
                                >
                                    Get it there by 12/24
                                </Heading>
                            </div>
                            <div className={classes.shipmentInfoRateConatiner}>
                                {shipmentRateInfoBelowCutOffDate?.map(
                                    (shipmentRateData: any, i) => (
                                        <div
                                            className={
                                                classes.shipmentInfoRateHoliday
                                            }
                                            style={{
                                                borderBottom:
                                                    shipmentRateInfoBelowCutOffDate?.length -
                                                        1 ===
                                                    i
                                                        ? '2px solid #CC0000'
                                                        : 'none',
                                                borderRadius:
                                                    shipmentRateInfoBelowCutOffDate?.length -
                                                        1 ===
                                                    i
                                                        ? '0px 0px 10px 10px'
                                                        : 'none',
                                            }}
                                        >
                                            <div
                                                className={
                                                    classes.shipmentInfoRateAndType
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.serviceNameRate
                                                    }
                                                >
                                                    {
                                                        shipmentRateData.serviceName
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.serviceNameRate
                                                    }
                                                >
                                                    $
                                                    {shipmentRateData.retailRateTotal.toFixed(
                                                        2
                                                    )}
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    classes.shipmentInfoDeliveryAndDate
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.deliveryDate
                                                    }
                                                >
                                                    {' '}
                                                    Estimated Delivery{' '}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.deliveryDate
                                                    }
                                                >
                                                    {dateFormate(
                                                        shipmentRateData.deliveryDate
                                                    )}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                )}
                                {shipmentRateInfoAboveCutOffDate?.map(
                                    (shipmentRateData: any, i) => (
                                        <div
                                            className={classes.shipmentInfoRate}
                                        >
                                            <div
                                                className={
                                                    classes.shipmentInfoRateAndType
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.serviceNameRate
                                                    }
                                                >
                                                    {
                                                        shipmentRateData.serviceName
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.serviceNameRate
                                                    }
                                                >
                                                    $
                                                    {shipmentRateData.retailRateTotal.toFixed(
                                                        2
                                                    )}
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    classes.shipmentInfoDeliveryAndDate
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.deliveryDate
                                                    }
                                                >
                                                    {' '}
                                                    Estimated Delivery{' '}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.deliveryDate
                                                    }
                                                >
                                                    {dateFormate(
                                                        shipmentRateData.deliveryDate
                                                    )}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={classes.shipmentInfoRateConatiner}>
                            {shipmentRate?.map((shipmentRateData: any, i) => (
                                <div className={classes.shipmentInfoRate}>
                                    <div
                                        className={
                                            classes.shipmentInfoRateAndType
                                        }
                                    >
                                        <Typography
                                            className={classes.serviceNameRate}
                                        >
                                            {shipmentRateData.serviceName}
                                        </Typography>
                                        <Typography
                                            className={classes.serviceNameRate}
                                        >
                                            $
                                            {shipmentRateData.retailRateTotal.toFixed(
                                                2
                                            )}
                                        </Typography>
                                    </div>
                                    <div
                                        className={
                                            classes.shipmentInfoDeliveryAndDate
                                        }
                                    >
                                        <Typography
                                            className={classes.deliveryDate}
                                        >
                                            {' '}
                                            Estimated Delivery{' '}
                                        </Typography>
                                        <Typography
                                            className={classes.deliveryDate}
                                        >
                                            {dateFormate(
                                                shipmentRateData.deliveryDate
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <Body
                    color="textMediumGray"
                    size={12}
                    weight="light"
                    textAlign="textCenter"
                >
                    All pricing shown is an estimate; variances in price at time
                    of purchase may occur. Actual price will be calculated in
                    store when package is processed and will be based on:
                    weight, package dimensions, and total travel distance.
                </Body>

                <div
                    className={`${classes.btnContainer} ${
                        bottomShadow ? '' : classes.shadow
                    }`}
                >
                    <ButtonBase
                        className={classes.btn}
                        onClick={handleStartShipment}
                    >
                        <span>Start a shipment</span>
                    </ButtonBase>
                </div>

                <div className={classes.btnContainerHome}>
                    <ButtonBase
                        className={classes.backToHome}
                        onClick={handleNavigateHome}
                    >
                        Back to home
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
};

export default ShippingRateDelivery;
