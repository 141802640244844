import React from 'react';


function IconInkRecycle() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="93" height="91" viewBox="0 0 93 91" fill="none">
<path d="M74.4097 43.6676C79.813 53.9976 68.6 62.697 61.4357 62.697C54.2714 62.697 43.1186 54.0277 48.4617 43.6676C54.1711 32.595 61.4357 22.561 61.4357 22.561C61.4357 22.561 68.5297 32.4219 74.4097 43.6676Z" fill="#00FFFF"/>
<path d="M61.4357 63.8259C56.5165 63.8259 50.6717 60.324 47.8421 55.6808C46.1514 52.9064 44.7115 48.4789 47.4583 43.1508C53.14 32.131 60.4498 22.0017 60.5226 21.9013C60.7358 21.6078 61.0744 21.4348 61.4357 21.4348C61.7994 21.4348 62.138 21.6103 62.3513 21.9038C62.4215 22.0042 69.5582 31.9604 75.4105 43.1458C78.1799 48.4388 76.7601 52.8537 75.0819 55.6256C72.2498 60.3014 66.3849 63.8284 61.4357 63.8284V63.8259ZM61.4306 24.5227C59.3034 27.5806 53.8826 35.6128 49.4626 44.1843C47.1949 48.5817 48.3764 52.2241 49.7686 54.5043C52.4628 58.9268 57.8159 61.5682 61.4357 61.5682C65.0554 61.5682 70.4537 58.9067 73.1504 54.4541C74.5275 52.1789 75.6915 48.5516 73.4112 44.1894C68.8658 35.4999 63.5328 27.558 61.4332 24.5227H61.4306Z" fill="#101820"/>
<path d="M62.5123 57.058C61.8877 57.058 61.3835 56.5538 61.3835 55.9292C61.3835 55.3045 61.8877 54.8003 62.5123 54.8003C65.2391 54.8003 69.4734 51.7224 69.5136 47.6361C69.5186 47.0114 70.0278 46.5223 70.6524 46.5173C71.277 46.5223 71.7762 47.034 71.7712 47.6561C71.721 52.8638 66.6639 57.0555 62.5123 57.0555V57.058Z" fill="#101820"/>
<path d="M58.3554 43.6676C63.7587 53.9976 52.5457 62.697 45.3814 62.697C38.2172 62.697 27.0644 54.0277 32.4075 43.6676C38.1168 32.595 45.3814 22.561 45.3814 22.561C45.3814 22.561 52.4755 32.4219 58.3554 43.6676Z" fill="#FF00FF"/>
<path d="M45.3814 63.8259C40.4622 63.8259 34.6174 60.324 31.7878 55.6808C30.0971 52.9064 28.6572 48.4789 31.404 43.1508C37.0858 32.131 44.3956 22.0017 44.4683 21.9013C44.6815 21.6078 45.0202 21.4348 45.3814 21.4348C45.7451 21.4348 46.0838 21.6103 46.297 21.9038C46.3672 22.0042 53.5039 31.9604 59.3563 43.1458C62.1256 48.4388 60.7058 52.8537 59.0276 55.6256C56.1955 60.3014 50.3307 63.8284 45.3814 63.8284V63.8259ZM45.3764 24.5227C43.2492 27.5806 37.8283 35.6128 33.4083 44.1843C31.1406 48.5817 32.3221 52.2241 33.7144 54.5043C36.4085 58.9268 41.7616 61.5682 45.3814 61.5682C49.0012 61.5682 54.3995 58.9067 57.0961 54.4541C58.4733 52.1789 59.6372 48.5516 57.357 44.1894C52.8116 35.4999 47.4785 27.558 45.3789 24.5227H45.3764Z" fill="#101820"/>
<path d="M46.4571 57.058C45.8325 57.058 45.3283 56.5538 45.3283 55.9292C45.3283 55.3045 45.8325 54.8003 46.4571 54.8003C49.1838 54.8003 53.4182 51.7224 53.4583 47.6361C53.4633 47.0114 53.9726 46.5223 54.5972 46.5173C55.2218 46.5223 55.721 47.034 55.716 47.6561C55.6658 52.8638 50.6087 57.0555 46.4571 57.0555V57.058Z" fill="#101820"/>
<path d="M44.0566 43.6676C49.4599 53.9976 38.2469 62.697 31.0826 62.697C23.9183 62.697 12.7655 54.0277 18.1086 43.6676C23.818 32.595 31.0826 22.561 31.0826 22.561C31.0826 22.561 38.1766 32.4219 44.0566 43.6676Z" fill="#FFFF00"/>
<path d="M31.0826 63.8259C26.1634 63.8259 20.3186 60.324 17.489 55.6808C15.7983 52.9064 14.3584 48.4789 17.1052 43.1508C22.787 32.131 30.0967 22.0017 30.1695 21.9013C30.3827 21.6078 30.7213 21.4348 31.0826 21.4348C31.4463 21.4348 31.785 21.6103 31.9982 21.9038C32.0684 22.0042 39.2051 31.9604 45.0574 43.1458C47.8268 48.4388 46.407 52.8537 44.7288 55.6256C41.8967 60.3014 36.0318 63.8284 31.0826 63.8284V63.8259ZM31.0776 24.5227C28.9503 27.5806 23.5295 35.6128 19.1095 44.1843C16.8418 48.5817 18.0233 52.2241 19.4155 54.5043C22.1097 58.9268 27.4628 61.5682 31.0826 61.5682C34.7023 61.5682 40.1006 58.9067 42.7973 54.4541C44.1744 52.1789 45.3384 48.5516 43.0581 44.1894C38.5127 35.4999 33.1797 27.558 31.0801 24.5227H31.0776Z" fill="#101820"/>
<path d="M32.1592 57.058C31.5346 57.058 31.0304 56.5538 31.0304 55.9292C31.0304 55.3045 31.5346 54.8003 32.1592 54.8003C34.886 54.8003 39.1203 51.7224 39.1605 47.6361C39.1655 47.0165 39.6697 46.5173 40.2893 46.5173H40.2993C40.9239 46.5223 41.4231 47.034 41.4181 47.6561C41.3679 52.8638 36.3108 57.0555 32.1592 57.0555V57.058Z" fill="#101820"/>
<path d="M3.70477 40.7903C3.66463 40.7903 3.6245 40.7903 3.58185 40.7828C2.96225 40.7151 2.51323 40.1607 2.58096 39.5411C3.72484 28.8699 8.7594 19.009 16.7565 11.7795C24.8113 4.49736 35.2341 0.486267 46.1034 0.486267C68.2133 0.486267 86.8916 17.0123 89.5557 38.9265C89.6309 39.5461 89.1894 40.108 88.5723 40.1833C87.9553 40.261 87.3908 39.817 87.3156 39.1999C84.7895 18.417 67.0745 2.74643 46.1059 2.74643C35.7985 2.74643 25.9125 6.54931 18.2741 13.4577C10.6884 20.316 5.91476 29.6651 4.82858 39.7844C4.76587 40.3639 4.27671 40.7928 3.70728 40.7928L3.70477 40.7903Z" fill="#231F20"/>
<path d="M82.7658 34.6922L88.2343 41.558C88.4099 41.7762 88.7561 41.7261 88.8589 41.4652L92.0723 33.2975C92.1751 33.0366 91.9569 32.7632 91.6785 32.8033L82.9965 34.1052C82.7181 34.1479 82.5902 34.474 82.7658 34.6922Z" fill="#231F20"/>
<path d="M46.4346 90.4863C24.3247 90.4863 5.6464 73.9603 2.98237 52.046C2.90712 51.4264 3.34861 50.8645 3.9657 50.7892C4.58279 50.7115 5.14721 51.1555 5.22246 51.7726C7.74852 72.5555 25.4635 88.2261 46.4321 88.2261C56.7395 88.2261 66.6255 84.4232 74.2639 77.5148C81.8496 70.6566 86.6233 61.3074 87.7095 51.1881C87.7747 50.5685 88.3316 50.1195 88.9512 50.1847C89.5708 50.2524 90.0198 50.8068 89.9521 51.4264C88.8082 62.0976 83.7736 71.9585 75.7765 79.188C67.7217 86.4701 57.2989 90.4812 46.4296 90.4812L46.4346 90.4863Z" fill="#231F20"/>
<path d="M9.77302 56.2803L4.30449 49.4145C4.12889 49.1963 3.78272 49.2465 3.67987 49.5073L0.466482 57.675C0.363634 57.9359 0.581873 58.2093 0.860317 58.1692L9.54224 56.8673C9.82068 56.8246 9.94861 56.4985 9.77302 56.2803Z" fill="#231F20"/>
</svg>
    );
}

export default IconInkRecycle;
