/* eslint-disable import/prefer-default-export */
/* eslint import/namespace: [2, { allowComputed: false }] */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { getMinutes } from 'date-fns';
import Cookies from 'js-cookie';
import { SessionManager, COMMON_HEADERS, getUserToken, useRefreshToken } from './sessionManager';
import { getSubscriptionKey } from './sign-on';
import { removeGustsessionCookies, removeSmsession } from '../utils/app-utils';

const guestInstance: AxiosInstance = axios.create({
    headers: {
        // 'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});
export async function guestTokenLogin(): Promise<any | null> {
    guestInstance.interceptors.request.use((config: any) => ({
        ...config,
        headers: {
            ...config.headers,
            'ocp-apim-subscription-key': process.env.REACT_APP_IS_USERMIGRATED_API_KEY || '',
        },
    }));
    try {
        const results: any = await guestInstance.get(
            `${process.env.REACT_APP_GUEST_API_URL}`
        );

        if (results?.data?.access_token) {
            SessionManager.setTokens(results.data);
            const userTokenResponse = await getUserToken();
            removeSmsession();
            removeGustsessionCookies();
            Cookies.set('SOMNIAPP', 'Y',
                {
                    domain: process.env.REACT_APP_Domain,
                    expires: 54 * 60 * 60 * 24 * 7,
                });

            return results.data;
        }
        return results;

    } catch (e) {
        return null;
    }
}