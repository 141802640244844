/* eslint import/namespace: [2, { allowComputed: false }] */
import axios, { AxiosInstance } from 'axios';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { getSubscriptionKey } from './sign-on';

export const atRecommendation = async (
  mboxes: string,
  storeId: string | undefined,
  zipCode: string | undefined
): Promise<any | null> => {
  const subscriptionKey = await getSubscriptionKey('StaplesSearchUX');
  if (process.env.REACT_APP_URL_SEARCH_UX) {
    axiosInstance.interceptors.request.use((config: any) => ({
      ...config,
      headers: {
        ...config.headers,
        client_id: subscriptionKey,
        withCredentials: true,
      },
    }));
    const reportData = await axiosInstance.post(
      process.env.REACT_APP_URL_SEARCH_UX,
      {
        serviceContext: {
          tenant: 'StaplesDotCom',
          legacyTenant: 10001,
          locale: 'en-US',
          channelId: 'WEB',
          siteId: 'US',
          zipCode,
          langId: -1,
          langCd: 'en',
          storeId,
          user: {},
          cisResponse: {},
        },
        query: {
          mboxes,
          appID: 'staples01',
          number: 25,
          engine: 'SPARX',
          EDDIE_CUSTOMER_TOKEN: '',
          inputs: {
            enableSparxV2: true,
            brandTransformationSwitch: true,
          },
        },
      }
    );
    if (reportData.data) {
      return reportData.data;
    }
  }

  return null;
};

export const atRecommendationRecentlyViewItems = async (
  mboxes: string,
  storeId: string | undefined,
  zipCode: string | undefined,
  onlyHeader = false
): Promise<any | null> => {
  const axiosInstanceRecentlyViewItems: AxiosInstance = axios.create({
    headers: {
      'content-type': 'application/json',
      'Cache-Control': 'no-cache',
      withCredentials: true,
    },
  });
  if (process.env.REACT_APP_EASY_API_AT_RECOMMENDATION) {
    const reportData = await axiosInstanceRecentlyViewItems.post(
      process.env.REACT_APP_EASY_API_AT_RECOMMENDATION,
      {
        serviceContext: {
          tenant: 'StaplesDotCom',
          legacyTenant: 10001,
          locale: 'en-US',
          channelId: 'WEB',
          siteId: 'US',
          zipCode,
          langId: -1,
          langCd: 'en',
          storeId,
          user: {},
          cisResponse: {},
        },
        query: {
          mboxes,
          appID: 'staples01',
          number: 25,
          engine: 'SPARX',
          EDDIE_CUSTOMER_TOKEN: '',
          inputs: {
            enableSparxV2: true,
            brandTransformationSwitch: true,
          },
        },
      }
    );
    if (onlyHeader) {
      return reportData.headers
    }
    if (reportData.data) {
      return reportData.data;
    }
  }

  return null;
};


export const getHeaderDataForCart = async (
): Promise<any | null> => {
  const axiosInstanceRecentlyViewItems: AxiosInstance = axios.create({
    headers: {
      'content-type': 'application/json',
      'Cache-Control': 'no-cache',
      withCredentials: true,
    },
  });
  if (process.env.REACT_APP_PWA_QUERIES) {
    const reportData = await axiosInstanceRecentlyViewItems.get(
      process.env.REACT_APP_PWA_QUERIES
    );

    return reportData.headers
  }

  return null;
};
