import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { optimoveEvents } from 'src/screens/optimove';
import { Route, Switch, Redirect } from 'react-router-dom';
import NavBottom from 'src/components/NavBottom';
import Cookies from 'js-cookie';
import SessionTimeout from 'src/components/session-timeout';
import ToastMessageContext, {
    useToastMessageContextState,
} from 'src/lib/contexts/toast-message-context';
import ProductBrowseContext, {
    useProductBrowseContextState,
} from 'src/lib/contexts/product-browse-context';
import CreateImportListContext, {
    useCreateImportListContextState,
} from 'src/lib/contexts/create-import-list-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import ROUTES, { DEEPLINK_ROUTES } from 'src/lib/routes';
import { SwipeableDrawer } from 'src/components/UI';
import {
    COACH_SCREENS_ROUTES,
    REWARDS_ENROLLMENT_ROUTES,
} from 'src/routers/routes';
import useInterval from 'src/hooks/useInterval';
import MyFavoritesController from 'src/controllers/my-favorites-controller';
import RewardsFlowController from 'src/controllers/rewards-enrollment-flow-controller';
import DonationFlowController from 'src/controllers/donation-flow-controller';
import ClaimSchoolController from 'src/controllers/claim-school-controller';
import InstallabilityController from 'src/controllers/installability-controller';
import TosController from 'src/controllers/tos-controller';
import RewardsReceivedController from 'src/controllers/rewards-received-controller';
import COLORS from 'src/lib/colors';
import MemberCardController from 'src/controllers/member-card-controller';
import ProductBrowseController from 'src/controllers/product-browse-controller';
import StickyNotification from 'src/components/StickyNotification';
import ImportListFlowController from 'src/controllers/import-lists-controller';
import { useFirebaseMessagingContext } from 'src/lib/contexts/firebase-messaging-context';
import GlobalToast from 'src/components/global-toast';
import {
    getClassRoomRewards,
    getClassRoomRewardsDonation,
    getStaplesRewardsData,
} from 'src/lib/api-client/wallet-activities';
import AccountSecurity from 'src/components/profiles/AccountSecurity';
import ScannableCouponDialog from 'src/components/ScannableCoupon/ScannableCouponDialog';
import PersonalData from 'src/components/profiles/PersonalData';
import Confirm from 'src/components/profiles/Confirm';
// import { getIdentity } from 'src/lib/api-client/beacons-adeptmind';
// import LoginContent from 'src/components/login-flow/user-login-screen';
import Consent from 'src/components/profiles/Consent';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import { getCartDetails, getDCNNumber } from 'src/lib/api-client/cart-api';
import {
    SessionManager,
    getLoginDetails,
    refreshRememberMe,
    setLoginDetails,
    updateToken,
    useRefreshToken,
} from 'src/lib/api-client/sessionManager';
import { useScreenOrderContext } from 'src/lib/contexts/screen-order-context';
import HomeContent from 'src/screens/home';
import UserManageProfile from 'src/screens/UserManageProfile';
import Stores from 'src/screens/stores';
import Rewards from 'src/screens/rewards-old/base';
import Lists from 'src/screens/lists/base';
import RewardsWalletBase from 'src/screens/rewards-wallet/RewardsWalletBase';
import OnboardingScreens from 'src/screens/Login/OnboardingScreens';
import ProductDetailsPage from 'src/screens/product-details-page';
import WrappedShopBase from 'src/screens/shop/shop';
import UserProfileScreen from 'src/screens/user-profile';
import LoginScreen from 'src/screens/Login';
import OrderHistoryAndCart from 'src/screens/orderHistoryAndCart';
import PointHitoryCelebrationScreen from 'src/components/PointHitoryCelebration';
import EmptyCart from 'src/screens/empty-cart';
import PreviousOrders from 'src/screens/previous-orders';
import {
    useUserInfo,
    useLocalUserInfo,
    useEventsReporter,
    eventMetricsMap,
    useRouter,
    useStickyNotification,
    LOCAL_STORAGE_KEYS,
    useStoreData,
    useGuestMode,
} from 'src/hooks';
import LocationCoachScreen from 'src/screens/CoachScreens/LocationCoachScreen';
import OtherBalance from 'src/screens/OtherBalance/OtherBalance';
import EasyRewards from 'src/screens/EasyRewards';
import NotificationCoachScreen from 'src/screens/CoachScreens/NotificationCoachScreen';
import {
    FavoriteOffersList,
    getFavoriteOffers,
    getLoyaltyOffers,
} from 'src/services/easyRewardsServices';
import Guest from 'src/screens/Guest';
import { IconWarning } from 'src/components/Icons';
import Button from 'src/components/UI/Button';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useUserLoginUpdateContextState } from 'src/lib/contexts/user-login-context';
import AppLink from 'src/components/link';
import { validateUserInfo } from 'src/utils/userUtils';
import LoadingOverlay from 'src/components/loading-overlay';
import {
    SetSomniAppCookies,
    removeSmsession,
    removeSomniAppCookies,
    removeStplSessionIdUniqueId,
} from 'src/lib/utils/app-utils';
import StoreModeOfferPage from 'src/screens/EasyRewards/StoreModeOfferPage';
import { mutateTermsAndConditions } from 'src/services/userServices';
import GuestLoginDrawer from 'src/screens/Login/GuestLoginDrawer/GuestLoginDrawer';
import { guestTokenLogin } from 'src/lib/api-client/guest-api';
import { makeSearchString } from 'src/lib/utils/url-utils';
import StoreModeDashboard from 'src/screens/store-mode/store-mode-dashboard';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import { GuestModeState, guestModeStateStorageKey } from 'src/hooks/useGuestMode';
import RewardsEnrollmentRouter from './RewardsEnrollmentRouter';

const basePaths = [
    ROUTES.home,
    ROUTES.stores,
    ROUTES.shop,
    ROUTES.rewards,
    ROUTES.userProfile,
    ROUTES.rewardsWallet,
    ROUTES.rewardsWallet_digitalCoupons,
    ROUTES.rewardsWallet_classroomRewards,
    ROUTES.rewardsWallet_staplesRewards,
    ROUTES.previousOrders,
    ROUTES.otherbalance,
];
function MainRouter() {
    const { location, query, push, replace, pathname } = useRouter();
    const { userInfo: localUserInfo } = useLocalUserInfo();
    const [apiCallInProgess, setApiCallInProgess] = useState(false);
    const productBrowseContextState = useProductBrowseContextState();
    const createImportListContextState = useCreateImportListContextState();
    const toastState = useToastMessageContextState();
    const { userInfo, isFetchingUserInfo, refetchUserInfo } = useUserInfo();
    const { subscribe } = useFirebaseMessagingContext();
    const { customEvent, analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const { shouldNavigateToStoreModeScreen } = useStoreData();
    const loginInstance: any = getLoginDetails();
    const { cartPage, ocpPage, printPage, storeServicesLink } =
        useTabsUIStateContext();
    const { hideFooter, setHideFooter } = useUserUpdateContext();
    const { isStoreModeOn } = useStoreData();
    const isStoreModeStickyNotification = !!document.getElementById(
        'store-mode-container'
    );
    const isPrintPage = storeServicesLink?.includes('printing');
    const isHolidayShipping = storeServicesLink?.includes(
        '/stores/services/holidayshipping'
    );
    console.log('🚀 ~ MainRouter ~ hideFooter:', hideFooter);
    const [tosSource, setTosSource] = useState<string | null>(null);
    const { stickyNotifications, addNotification, addGroup } =
        useStickyNotification();

    const {
        cartCount,
        updateCartCount,
        activeIframeId,
        updateWebViewLoading,
        isWebViewLoading,
    } = useCartCountContext();
    const { isGuestModeOn } = useGuestMode();

    const inferredZipCode =
        userInfo?.currentLocationZipCode ||
        localUserInfo?.zipCode ||
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO) || '{}')
            ?.zipCode;

    const rewardsNumber = userInfo?.rewardsNumber || '0';

    const { mutate: setTermsAndConditions } = mutateTermsAndConditions({
        onSuccess: () =>
            push(REWARDS_ENROLLMENT_ROUTES.welcomeAnimation, {
                onCompleteNavigateTo: REWARDS_ENROLLMENT_ROUTES.favorites,
            }),
    });

    const { data: staplesRewardsDetail } = useQuery(
        ['details', rewardsNumber],
        () => getStaplesRewardsData(rewardsNumber),
        {
            enabled: !isFetchingUserInfo && !!inferredZipCode,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );
    const { data: cartDetails, refetch } = useQuery(
        ['getCartDetails'],
        () => getCartDetails(isGuestModeOn),
        {
            enabled: SessionManager.isLoggedIn || isGuestModeOn,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: Infinity,
            cacheTime: Infinity,
            onSuccess: (cartInfo: any) => {
                const cartCountData =
                    cartInfo?.data?.cartInfo?.cartAttributes?.numberOfItems;
                updateCartCount(cartCountData);
            },
        }
    );

    const { data: classroomRewardTb } = useQuery(
        ['getClassRoomRewardsDonation'],
        () => getClassRoomRewardsDonation(),
        {
            enabled: SessionManager.isLoggedIn,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    const { data: classroomDonation } = useQuery(
        ['getClassRoomRewards'],
        () => getClassRoomRewards(),
        {
            enabled: SessionManager.isLoggedIn,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );
    const {
        data: offerList,
        isFetching,
        refetch: refetchFavOffers,
    } = getFavoriteOffers({
        initialData: [] as unknown as FavoriteOffersList,
        ...NO_REFETCH,
    });

    const refreshWebView = () => {
        const documentData = document?.getElementById?.(
            activeIframeId
        ) as HTMLIFrameElement;
        if (activeIframeId && documentData) {
            documentData?.contentWindow?.location?.reload?.();
        }
    };

    useEffect(() => {
        const refresSession = async (e: any) => {
            const guestModeState: GuestModeState = JSON.parse(
                sessionStorage.getItem(guestModeStateStorageKey) || 'null'
            ) || {
                isGuestModeOn: false,
            };
            console.log("🚀 ~ refresSession ~ guestModeState:", guestModeState)

            e.preventDefault();
            if (loginInstance?.access_token) {
                if (document.visibilityState === 'visible') {
                    SetSomniAppCookies();
                }
                if (document.visibilityState === 'hidden') {
                    removeSomniAppCookies();
                }
            }
            console.log(
                '🚀 ~ file: index.tsx:336 ~ useEffect ~ document.visibilityState:',
                document.visibilityState
            );
            if (
                !guestModeState?.isGuestModeOn &&
                !apiCallInProgess &&
                loginInstance?.access_token &&
                document.visibilityState === 'visible'
            ) {
                updateWebViewLoading(true);
                setApiCallInProgess(true);
                const checkedValidSession: any = await updateToken();
                if (checkedValidSession.status) {
                    useRefreshToken(
                        checkedValidSession.newTime,
                        checkedValidSession.newDateValue,
                        true
                    );
                } else if (!checkedValidSession?.status) {
                    const useRefreshRememberMeResponse: any =
                        await refreshRememberMe();
                    if (!useRefreshRememberMeResponse?.status) {
                        // This will use to redirect user to login screen
                        const loginInstanceData: any = getLoginDetails();
                        SessionManager.session?.killSession();
                        setLoginDetails({});
                        SessionManager.isLoggedIn = false;
                        removeSmsession();
                        removeStplSessionIdUniqueId();
                        window.location.replace(`/somniapp/login`);
                        // eslint-disable-next-line no-else-return
                    } else {
                        SessionManager.session.killSession();
                        useRefreshToken(
                            useRefreshRememberMeResponse.newTime,
                            useRefreshRememberMeResponse.newDateValue,
                            true
                        );
                    }
                }
                updateWebViewLoading(false);
                setApiCallInProgess(false);
            } else if (
                guestModeState?.isGuestModeOn &&
                !apiCallInProgess &&
                loginInstance?.access_token &&
                document.visibilityState === 'visible'
            ) {
                setApiCallInProgess(true);
                await guestTokenLogin();
                setApiCallInProgess(false);
            }
        };

        document.addEventListener('visibilitychange', refresSession);
        if (SessionManager.isLoggedIn) {
            SetSomniAppCookies();
            refetch();
        }
        subscribe();

        return () => {
            document.removeEventListener('visibilitychange', refresSession);
        };
    }, []);
    const analyticsLoggedInUser = async () => {
        const favOfferData = await refetchFavOffers();
        const selectedOfferData =
            favOfferData?.data
                ?.filter((offer) => {
                    if (offer.subscribed) {
                        return offer?.name?.slice(0, 11);
                    }
                    return null;
                })
                ?.map((fdata) => fdata?.name)
                ?.join(',') || '';
        if (selectedOfferData) {
            analyticsScreenCustomNames({
                user_point_categories: selectedOfferData,
            });
        }
    };

    useEffect(() => {
        if (SessionManager.isLoggedIn || isGuestModeOn) {
            refetch();
        }
        if (SessionManager.isLoggedIn) {
            analyticsLoggedInUser();
        }
    }, [SessionManager.isLoggedIn, isGuestModeOn]);

    useEffect(() => {
        if (!isWebViewLoading) {
            refreshWebView();
        }
    }, [isWebViewLoading]);
    useEffect(() => {
        if (document.visibilityState === 'visible') {
            console.log(
                'visible state - setting up SOMNI app cookie',
                document.visibilityState
            );
            SetSomniAppCookies();
            refreshWebView();
        }
        if (document.visibilityState === 'hidden') {
            console.log(
                'hidden state - removing SOMNI app cookie',
                document.visibilityState
            );
            removeSomniAppCookies();
        }
    }, [document?.visibilityState]);

    getLoyaltyOffers({
        offerType: 'MASS',
        config: {
            enabled: !apiCallInProgess && !isGuestModeOn,
            onSuccess: (loyaltyRes) => {
                if (loyaltyRes) {
                    const offers1 = loyaltyRes?.offers || [];
                    const offers2 = loyaltyRes?.offers || [];
                    const currentDate = new Date();
                    const twentyFourHrs = 86_400_000;
                    if (
                        !offers1.some((item) => {
                            if (item.enddate) {
                                if (
                                    currentDate.getTime() -
                                        new Date(item.enddate).getTime() <=
                                    twentyFourHrs
                                ) {
                                    addNotification({
                                        id: item.offerid,
                                        group: 'loyalty',
                                    });
                                    return true;
                                }
                            }
                            return false;
                        })
                    ) {
                        offers2.some((item) => {
                            if (item.enddate) {
                                if (
                                    currentDate.getTime() -
                                        new Date(item.enddate).getTime() <=
                                    twentyFourHrs
                                ) {
                                    addNotification({
                                        id: item.offerid,
                                        group: 'loyalty',
                                    });
                                    return true;
                                }
                            }
                            return false;
                        });
                    }
                }
            },
        },
    });

    const route = sessionStorage.getItem('route');

    // Handle deeplink here
    useEffect(() => {
        if (
            route &&
            SessionManager.isLoggedIn &&
            !pathname.includes('coachscreen') &&
            !pathname.includes('rewards-enrollment') &&
            (route.includes(ROUTES.previousOrders) ||
                route.includes(ROUTES.rewardsWallet_digitalCoupons) ||
                route.includes(ROUTES.rewardsWallet_classroomRewards) ||
                route.includes(ROUTES.rewardsWallet_staplesRewards) ||
                route.includes(ROUTES.lists) ||
                route.includes(ROUTES.stores) ||
                route.includes(ROUTES.coupons) ||
                route.includes(ROUTES.rewardsWallet) ||
                route.includes(ROUTES.userProfile) ||
                route.includes(ROUTES.home) ||
                route.includes(ROUTES.shop) ||
                route.includes(ROUTES.activateAllStoreOffers))
        ) {
            sessionStorage.removeItem('route');
            if (route === '/home/online' || route === '/home/retail') {
                localStorage.setItem('shoppingOnlineRetail', route);
                replace(ROUTES.home);
            } else if (
                route.includes('/coupons/') &&
                route?.split('/')[2]?.length >= 5
            ) {
                replace({
                    pathname: ROUTES.coupons,
                    search: makeSearchString({
                        couponNumber: route?.split('/')[2],
                        deepLink: true,
                    }),
                });
            } else {
                replace(route);
            }
        }
    }, [route, location]);

    const analyticsGAScreenView = () => {
        // @Screen name enrollflow_nonmigrated easy rewards enroll
        if (pathname === ROUTES.home) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_home`,
                firebase_screen_class: `app_home`,
            });
        } else if (pathname === ROUTES.shop) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_shop`,
                firebase_screen_class: `app_shop`,
            });
        } else if (pathname === ROUTES.stores) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_stores services`,
                firebase_screen_class: `app_stores services`,
            });
        } else if (
            pathname === ROUTES.userProfile ||
            pathname === ROUTES.otherbalance
        ) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_account`,
                firebase_screen_class: `app_account`,
            });
        }
    };

    useEffect(() => {
        analyticsGAScreenView();
        if (pathname !== ROUTES.rewardsWallet) {
            setHideFooter(false);
        }
    }, [pathname]);

    const showNavBar =
        basePaths.some((path) => path === location.pathname) || ocpPage;
    console.log('🚀 ~ MainRouter ~ showNavBar:', showNavBar);

    const fetchDCN = async () => {
        try {
            const directCustomerNumber = await getDCNNumber(
                SessionManager.access_token
            );
            if (directCustomerNumber) {
                Cookies.set('DirectCustomerNumber', directCustomerNumber);
            }
        } catch (error) {
            console.log('Error fetching DCN number: ', error);
        }
    };

    // useEffect(() => {
    //     const cartCountData =
    //         cartDetails?.data?.cartInfo?.cartAttributes?.numberOfItems;
    //     if (cartCountData) {
    //         updateCartCount(cartCountData);
    //     }
    // }, [cartDetails?.data]);

    useEffect(() => {
        if (SessionManager.isLoggedIn) {
            refetch();
        }
        const directCustomerNumber = Cookies.get('DirectCustomerNumber');
        if (!directCustomerNumber && SessionManager.access_token) {
            fetchDCN();
        }
    }, [location]);

    useEffect(() => {
        const cta = () => {
            customEvent(eventMetricsMap.wallet_tophatnotification_tapped);
            analyticsCustomEvent(eventMetricsMap.top_hat, {
                click_text: `view - 'you've received a new staples reward!'`,
                element_location: 'top hat',
            });
            optimoveEvents(eventMetricsMap.scm_top_hat, {
                click_text: `view - 'you've received a new staples reward!'`,
                element_location: 'top hat',
            });
            push(ROUTES.otherbalance, {
                otherBalance: 'notify',
            });
        };
        addGroup('new-reward', {
            content: [
                'You’ve received a new Staples Reward!',
                'You’ve received new Staples Rewards!',
            ],
            ctaLabel: 'View',
            cta,
            screen: 'all',
        });
        addGroup('7-days', {
            content: [
                <span>
                    Staples Rewards{' '}
                    <span
                        style={{ fontWeight: 'bold', color: COLORS.brandRed }}
                    >
                        expiring soon!
                    </span>
                </span>,
                <span>
                    Staples Rewards{' '}
                    <span
                        style={{ fontWeight: 'bold', color: COLORS.brandRed }}
                    >
                        expiring soon!
                    </span>
                </span>,
            ],
            ctaLabel: 'Redeem',
            cta,
            screen: 'all',
        });
        addGroup('24-hrs', {
            content: [
                <span>
                    Staples Rewards{' '}
                    <span
                        style={{ fontWeight: 'bold', color: COLORS.brandRed }}
                    >
                        expiring in 24hrs!
                    </span>
                </span>,
                <span>
                    Staples Rewards{' '}
                    <span
                        style={{ fontWeight: 'bold', color: COLORS.brandRed }}
                    >
                        expiring in 24hrs!
                    </span>
                </span>,
            ],
            ctaLabel: 'Redeem',
            cta,
            screen: 'all',
        });
    }, []);

    useEffect(() => {
        if (userInfo) {
            const handleAccept = () => {
                if (validateUserInfo({ ...userInfo })) {
                    setTermsAndConditions();
                } else {
                    push(REWARDS_ENROLLMENT_ROUTES.missingInfoConfimation);
                }
            };

            addGroup('loyaltyTOS', {
                content: [
                    <span
                        style={{
                            display: 'flex',
                            alignContent: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                                paddingRight: '8px',
                            }}
                        >
                            <IconWarning />
                        </div>
                        <span>
                            To get the most out of{' '}
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: COLORS.brandRed,
                                }}
                            >
                                Easy Rewards
                            </span>
                            , accept our updated{' '}
                            <AppLink
                                onClick={() => {
                                    analyticsCustomEvent(
                                        eventMetricsMap.sticky_notification_accept_terms,
                                        {
                                            click_text: 'terms & conditions',
                                        }
                                    );
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/lp/easy-rewards-terms-conditions`
                                    );
                                }}
                                style={{
                                    fontWeight: 'bold',
                                    color: COLORS.brandCyan,
                                }}
                            >
                                &nbsp;Terms & Conditions.
                            </AppLink>
                        </span>
                    </span>,
                ],
                ctaLabel: 'Accept',
                cta: async () => {
                    analyticsCustomEvent(
                        eventMetricsMap.sticky_notification_accept_terms,
                        {
                            click_text: 'accept',
                        }
                    );
                    handleAccept();
                },
                isDismissable: false,
                screen: ROUTES.rewardsWallet,
            });
        }
    }, [userInfo]);

    useEffect(() => {
        if (staplesRewardsDetail?.data?.couponDataList) {
            staplesRewardsDetail?.data?.couponDataList.forEach(
                (coupon: any) => {
                    const { category } = coupon;
                    const expirationDate = new Date(
                        coupon.couponInfo.expirationDate
                    );
                    const millisecondsToExpiration =
                        Date.parse(expirationDate.toString()) - Date.now();
                    if (category === 'Reward') {
                        if (millisecondsToExpiration > 604_800_000) {
                            addNotification({
                                id: coupon.couponNumber,
                                group: 'new-reward',
                            });
                        } else if (millisecondsToExpiration > 86_400_000) {
                            addNotification({
                                id: coupon.couponNumber,
                                group: '7-days',
                            });
                        } else if (millisecondsToExpiration > 0) {
                            addNotification({
                                id: coupon.couponNumber,
                                group: '24-hrs',
                            });
                        }
                    }
                }
            );
        }
    }, [staplesRewardsDetail]);

    useEffect(() => {
        if (
            shouldNavigateToStoreModeScreen &&
            location.pathname !== ROUTES.activateAllStoreOffers
        ) {
            console.log('location', location);
            push(ROUTES.storeMode);
        }
    }, [shouldNavigateToStoreModeScreen]);

    return (
        <ProductBrowseContext.Provider value={productBrowseContextState}>
            <CreateImportListContext.Provider
                value={createImportListContextState}
            >
                <ToastMessageContext.Provider value={toastState}>
                    <GlobalToast />
                    <SessionTimeout />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height:
                                pathname === ROUTES.shop &&
                                isStoreModeStickyNotification
                                    ? 'calc(100vh - 66px)'
                                    : '100vh',
                        }}
                        className={
                            pathname === ROUTES.shop && !isStoreModeOn
                                ? 'overflowHidden'
                                : ''
                        }
                    >
                        {showNavBar &&
                            Object.keys(stickyNotifications).map((group) => {
                                const {
                                    notifications,
                                    cta,
                                    ctaLabel,
                                    content,
                                    screen,
                                    isDismissable,
                                } = stickyNotifications[group];
                                return notifications?.length > 0 ? (
                                    <StickyNotification
                                        count={notifications?.length}
                                        content={content}
                                        group={group}
                                        cta={cta}
                                        ctaLabel={ctaLabel}
                                        screen={screen}
                                        isDismissable={isDismissable}
                                    />
                                ) : null;
                            })}
                        <RewardsEnrollmentRouter />
                        <Switch>
                            <Route
                                path={ROUTES.productDetailsPage}
                                render={() => <ProductDetailsPage />}
                            />
                            <Route
                                path={ROUTES.home}
                                render={
                                    inferredZipCode
                                        ? () => <HomeContent />
                                        : undefined
                                }
                            />
                            <Route
                                path={ROUTES.stores}
                                render={() => <Stores />}
                            />
                            <Route
                                path={ROUTES.personalData}
                                render={PersonalData}
                            />
                            <Route
                                path={ROUTES.accountSecurity}
                                render={AccountSecurity}
                            />
                            <Route path={ROUTES.confirm} render={Confirm} />
                            <Route path={ROUTES.consent} render={Consent} />
                            <Route
                                path={ROUTES.userManageProfile}
                                render={UserManageProfile}
                            />
                            <Route
                                path={ROUTES.userProfile}
                                render={
                                    isGuestModeOn ? Guest : UserProfileScreen
                                }
                                exact
                            />
                            <Route
                                path={ROUTES.otherbalance}
                                render={OtherBalance}
                            />
                            <Route
                                path={ROUTES.rewardsWallet}
                                render={() =>
                                    inferredZipCode ? (
                                        <EasyRewards />
                                    ) : undefined
                                }
                            />
                            <Route
                                path={`${ROUTES.rewardsWallet}/:pageName`}
                                render={
                                    inferredZipCode
                                        ? RewardsWalletBase
                                        : undefined
                                }
                            />
                            <Redirect
                                from="/sharelist"
                                to={{
                                    pathname: `${ROUTES.lists}/${query.listID}`,
                                    search: '?type=shared',
                                }}
                            />
                            <Route
                                path={ROUTES.coupons}
                                render={ScannableCouponDialog}
                            />
                            <Route
                                path={ROUTES.shop}
                                render={WrappedShopBase}
                            />
                            <Route
                                path={ROUTES.onboarding}
                                render={OnboardingScreens}
                            />
                            <Route
                                path={ROUTES.previousOrders}
                                render={PreviousOrders}
                            />
                            <Route
                                path={ROUTES.cart}
                                render={OrderHistoryAndCart}
                            />
                            <Route path={COACH_SCREENS_ROUTES.location}>
                                <LocationCoachScreen />
                            </Route>
                            <Route path={COACH_SCREENS_ROUTES.notification}>
                                <NotificationCoachScreen />
                            </Route>
                            <Route path={ROUTES.emptyCart} render={EmptyCart} />
                            <Route path={ROUTES.storeMode}>
                                <StoreModeDashboard />
                            </Route>
                            <Route path={ROUTES.activateAllStoreOffers}>
                                <StoreModeOfferPage />
                            </Route>
                            {/* <Redirect to={ROUTES.home} /> */}
                        </Switch>
                        {showNavBar &&
                        inferredZipCode &&
                        !printPage &&
                        !isPrintPage &&
                        !hideFooter &&
                        !isHolidayShipping &&
                        !cartPage ? (
                            <NavBottom />
                        ) : null}
                    </div>
                    <MemberCardController />
                    <PointHitoryCelebrationScreen />
                    <DonationFlowController />
                    <RewardsFlowController />
                    <RewardsReceivedController />
                    <ClaimSchoolController />
                    <MyFavoritesController />
                    <ImportListFlowController />
                    <ProductBrowseController />
                </ToastMessageContext.Provider>
            </CreateImportListContext.Provider>
            <SwipeableDrawer
                anchor="bottom"
                open={!!tosSource}
                onClose={() => {
                    setTosSource(null);
                }}
                onOpen={() => console.log('open')}
            >
                <iframe
                    id="privacy-link"
                    title="privacy link iFrame"
                    style={{ height: '90vh', width: '100%', marginTop: 16 }}
                    src={tosSource || '#'}
                />
            </SwipeableDrawer>
            {apiCallInProgess && (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={apiCallInProgess}
                />
            )}
            <GuestLoginDrawer />
        </ProductBrowseContext.Provider>
    );
}

/**
 * Contains the main bottom nav bar, the five core screens, and any modal menus
 */
export default MainRouter;
