// creating-shipping-review-information// creating-shipping-package-information
/* eslint-disable max-len */

import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    ButtonBase,
    makeStyles,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import { BorderBottom, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import useRouter from 'src/hooks/useRouter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import LoadingOverlay from 'src/components/loading-overlay';
import {
    getPartialShipment,
    getShipmentRates,
} from 'src/lib/api-client/shipment-services';
import moment from 'moment';
import { phoneNumberFormat } from 'src/lib/utils/string-utils';
import { Body, Heading } from 'src/components/UI';
import { isListEnabled } from 'src/lib/api-client/lists';
import { useQuery } from 'react-query';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import { mergeClasses } from 'src/stylesheets/utils';
import Header from 'src/components/header';

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '-1px',
    },
    headerSubTitle: {
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '25.96px',
        letterSpacing: '-0.6px',
        width: '274px',
    },
    createShippingReviewHeaderTitle: {
        marginBottom: '24px',
    },
    senderInformation: {
        minHeight: '160px',
        padding: '24px',
        background: '#FFFFFF',
        borderRadius: '15px',
        wordBreak: 'break-word',
    },
    senderYourInformation: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },
    senderYourInformationText: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '-0.200px',
        color: '#000000',
    },
    editBtn: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#086DD2',
    },
    senderNameInformation: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        color: '#1E1E1E',
    },
    senderNameInformationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    senderPrimaryText: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#979797',
    },
    senderAddressContainer: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#1E1E1E',
    },
    recipientInformation: {
        minHeight: '160px',
        padding: '24px',
        background: '#FFFFFF',
        borderRadius: '15px',
        marginTop: '24px',
        wordBreak: 'break-word',
    },
    recipientYourInformation: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },
    recipientYourInformationText: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '-0.200px',
        color: '#000000',
    },
    recipientNameInformation: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        color: '#1E1E1E',
    },
    recipientNameInformationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    recipientPrimaryText: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#979797',
    },
    recipientAddressContainer: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#1E1E1E',
    },
    addressAlign: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#1E1E1E',
        wordBreak: 'break-all',
    },
    mainPackageConatiner: {
        margin: '24px',
    },
    packageInformationContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '24px',
        background: '#FFFFFF',
        borderRadius: '15px',
    },
    packageInformation: {
        display: 'flex',
        padding: '24px',
        flexDirection: 'column',
        gap: 10,
        borderBottom: '1px solid #D9D9D6',
    },
    packageInformationTextConatiner: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    packageInformationText: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '-0.200px',
        color: '#000000',
    },
    packageCountText: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
    },
    packageNumber: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
        color: '#000000',
    },
    packageItems: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#1E1E1E',
        display: 'flex',
        justifyContent: 'space-between',
        '& div:first-child': {
            width: '85%',
        },
    },
    packageDetails: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #D9D9D6',
        paddingBottom: '20px',
        '&:last-child': {
            borderBottom: '0px none',
        },
    },
    accordianConatiner: {
        border: '0px !important',
        boxShadow: 'none',
        borderBottomLeftRadius: '15px !important',
        borderBottomRightRadius: '15px !important',
        '&.MuiAccordion-root.Mui-expanded': {
            margin: '0px !important',
        },
    },
    accordianDetails: {
        flexDirection: 'column',
        gap: '22px',
        padding: '0px 24px',
    },
    accordianSummary: {
        padding: '12px 24px',
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
    },
    nextBtn: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        marginTop: '30px',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    nextBtnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    errorAlign: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.20px',
        textAlign: 'center',
        color: '#c0392b',
    },
    deliverEstimates: {
        marginTop: '25px',
    },
    deliveryTitle: {
        fontSize: '22px',
        fontWeight: 700,
    },
    helperText: {
        lineHeight: '18px',
        marginTop: '10px',
    },
    shipmentInfoRateContainer: {
        borderRadius: '10px',
        marginTop: '24px',
    },
    shipmentInfoRateAndType: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    shipmentInfoDeliveryAndDate: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
    },
    shipmentInfoRate: {
        display: 'flex',
        flexDirection: 'column',
        height: '90px',
        padding: '24px',
        justifyContent: 'center',
        // '&:nth-child(even)': {background: '#F2F2F2'},
        '&:nth-child(odd)': { background: '#FFFFFF' },
        '&:first-child': {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
        },
        '&:last-child': {
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
    },
    shipmentInfoRateHoliday: {
        display: 'flex',
        flexDirection: 'column',
        height: '90px',
        padding: '24px',
        justifyContent: 'center',
        '& last-of-type': {
            borderBottom: '2px solid #CC0000',
        },
        '&:nth-child(odd)': { background: '#FFFFFF' },
        borderRight: '2px solid #cc0000',
        borderLeft: '2px solid #cc0000',
    },
    serviceNameRate: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    deliveryDate: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    holidayDisAlign: {
        background: '#CC0000',
        borderRadius: '15px 15px 0px 0px',
        height: '34px',
        display: 'flex',
        alignItems: 'center',
        color: '#ffffff',
        width: '100%',
        padding: '0px 16px',
        marginBottom: '-24px',
        marginTop: '5px',
    },
}));
const cutoffDate = new Date('12/24/2024');

interface reviewsProps {
    senderInfo: any;
    recipientInfo: any;
    packageInfo: any;
    setQrCodeValue: any;
}
const CreateShippingReviewInformation = ({
    senderInfo,
    recipientInfo,
    packageInfo,
    setQrCodeValue,
}: reviewsProps) => {
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const ref = useRef<any>();
    const [shipmentRateInfo, setShipmentRateInfo] = useState([]);
    const [
        shipmentRateInfoBelowCutOffDate,
        setShipmentRateInfoBelowCutOffDate,
    ] = useState([]);
    const [
        shipmentRateInfoAboveCutOffDate,
        setShipmentRateInfoAboveCutOffDate,
    ] = useState([]);
    const { data: HolidayShippingEnabled } = useQuery(
        ['HolidayShippingEnabled'],
        () => isListEnabled('HolidayShipping'),
        {
            refetchOnMount: true,
        }
    );
    const { updateReviewStage } = useContext(ShippingInfoContext);
    const getRates = async () => {
        setIsLoading(true);

        const weights = Object.values(packageInfo)?.map((pkg: any) =>
            Number(pkg.weight)
        );
        const sumOfWeights = weights?.reduce((acc, weight) => acc + weight, 0);

        const shipmentRates = await getShipmentRates(
            senderInfo?.zipCode,
            recipientInfo?.zipCode,
            recipientInfo?.countryCode,
            'US',
            sumOfWeights.toString()
        );
        setIsLoading(false);
        const sortedRates = shipmentRates?.data?.data[0].rates?.sort(
            (a: any, b: any) => a.total - b.total
        );
        const sortedDate = shipmentRates?.data?.data[0].rates?.sort(
            (a: any, b: any) =>
                new Date(a.deliveryDate).getDate() -
                new Date(b.deliveryDate).getDate()
        );
        if (HolidayShippingEnabled?.isenabled) {
            const filteredDataBelowCutOffData = sortedDate?.filter(
                (rate: any) => new Date(rate.deliveryDate) <= cutoffDate
            );
            const filteredDataAboveCutOffDate = sortedDate?.filter(
                (rate: any) => new Date(rate.deliveryDate) > cutoffDate
            );
            setShipmentRateInfoBelowCutOffDate(filteredDataBelowCutOffData);
            setShipmentRateInfoAboveCutOffDate(filteredDataAboveCutOffDate);
        }

        setShipmentRateInfo(sortedRates);
    };
    useEffect(() => {
        getRates();
        updateReviewStage(true);
    }, [
        senderInfo?.address?.zipCode,
        recipientInfo?.address?.zipCode,
        HolidayShippingEnabled?.isenabled,
    ]);

    const modiFyPackage = () => {
        const data = Object.keys(packageInfo).map((dp, i) => {
            console.log({ dp });
            return {
                description: packageInfo[dp].package,
                packagenumber: i + 1,
            };
        });
        return data;
    };
    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: -114,
                behavior: 'smooth',
            });
        }, 1000);
    };
    const handleNavigation = async () => {
        setError('');
        setIsLoading(true);
        const bodyData = {
            shippermodel: {
                fullName: `${senderInfo?.firstName} ${senderInfo?.lastName}`,
                companyName: senderInfo?.companyName || '',
                street1: senderInfo?.address,
                street2: senderInfo?.aptNumb,
                city: senderInfo?.city,
                state: senderInfo?.state,
                postalCode: senderInfo?.zipCode,
                phoneNumber: senderInfo?.phoneNumber,
                email: senderInfo?.email || '',
                countryCode: 1,
                country: 'US',
            },
            recipientmodel: {
                fullName: `${recipientInfo?.firstName} ${recipientInfo?.lastName}`,
                companyName: recipientInfo?.companyName || '',
                street1: recipientInfo?.address,
                street2: recipientInfo?.aptNumb,
                city: recipientInfo?.city,
                state: recipientInfo?.state,
                postalCode: recipientInfo?.zipCode,
                phoneNumber: recipientInfo?.phoneNumber,
                email: recipientInfo?.email || '',
                countryCode: 1,
                country: 'US',
            },
            packagemodel: modiFyPackage(),
        };

        const responseData = await getPartialShipment(bodyData);
        setIsLoading(false);
        updateReviewStage(false);
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'finalize label',
            element_location: 'review & save',
        });

        if (responseData?.shipmentnumber) {
            setQrCodeValue(responseData?.shipmentnumber);
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=CreateShippingQRInformation',
            });
        } else {
            setError(
                responseData?.errorMessage ||
                    'Sorry Unable to Process your Request at this Moment, Please Try Again Later!!'
            );
            scrollToTop();
        }
    };
    const habdleEditSenderInfo = () => {
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=createShipmentSenderInfo',
        });
    };
    const habdleEditRecipientInfo = () => {
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=CreateShippingRecipientInformation',
        });
    };
    const habdleEditPackageInfo = () => {
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=CreateShippingPackageInformation',
        });
    };
    const dateFormat = (date: string) => {
        const defaultFormat = 'ddd MM/DD/YY';
        const formatedDate = moment(date).format(defaultFormat);
        return formatedDate;
    };
    return (
        <div className={classes.mainPackageConatiner} ref={ref}>
            <Typography className={classes.errorAlign}>{error}</Typography>
            <div className={classes.createShippingReviewHeaderTitle}>
                <Typography className={classes.headerTitle}>
                    Review and finalize
                </Typography>
            </div>
            <div className={classes.senderInformation}>
                <div className={classes.senderYourInformation}>
                    <Typography className={classes.senderYourInformationText}>
                        Your information
                    </Typography>
                    <ButtonBase
                        className={classes.editBtn}
                        onClick={habdleEditSenderInfo}
                    >
                        Edit
                    </ButtonBase>
                </div>
                <div className={classes.senderNameInformationContainer}>
                    <Typography className={classes.senderNameInformation}>
                        {senderInfo.firstName} {senderInfo.lastName}
                    </Typography>
                </div>
                <div className={classes.senderAddressContainer}>
                    <Typography className={classes.addressAlign}>
                        {senderInfo.companyName}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {senderInfo.address}&nbsp;{senderInfo.aptNumb}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {senderInfo.city},&nbsp;{senderInfo.state}&nbsp;
                        {senderInfo.zipCode}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {phoneNumberFormat(senderInfo.phoneNumber)}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {senderInfo.email}
                    </Typography>
                </div>
            </div>
            <div className={classes.recipientInformation}>
                <div className={classes.recipientYourInformation}>
                    <Typography
                        className={classes.recipientYourInformationText}
                    >
                        Recipient information
                    </Typography>
                    <ButtonBase
                        className={classes.editBtn}
                        onClick={habdleEditRecipientInfo}
                    >
                        Edit
                    </ButtonBase>
                </div>
                <div className={classes.recipientNameInformationContainer}>
                    <Typography className={classes.recipientNameInformation}>
                        {recipientInfo.firstName} {recipientInfo.lastName}
                    </Typography>
                </div>
                <div className={classes.recipientAddressContainer}>
                    <Typography className={classes.addressAlign}>
                        {recipientInfo.companyName}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {recipientInfo.address}&nbsp;{recipientInfo.aptNumb}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {recipientInfo.city},&nbsp;{recipientInfo.state}&nbsp;
                        {recipientInfo.zipCode}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {phoneNumberFormat(recipientInfo.phoneNumber)}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {recipientInfo.email}
                    </Typography>
                </div>
            </div>
            <div className={classes.packageInformationContainer}>
                <div className={classes.packageInformation}>
                    <div className={classes.packageInformationTextConatiner}>
                        <Typography className={classes.packageInformationText}>
                            Number of packages
                        </Typography>
                        <ButtonBase
                            className={classes.editBtn}
                            onClick={habdleEditPackageInfo}
                        >
                            Edit
                        </ButtonBase>
                    </div>
                    <Typography className={classes.packageCountText}>
                        {Object.keys(packageInfo).length}
                    </Typography>
                </div>
                <Accordion className={classes.accordianConatiner}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel-header-accordian-conatiner"
                        className={classes.accordianSummary}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.packageInformationText}>
                            Package content
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordianDetails}>
                        {Object.keys?.(packageInfo)?.map((pakData, index) => (
                            <div className={classes.packageDetails}>
                                <Typography className={classes.packageNumber}>
                                    Package&nbsp;{index + 1}
                                </Typography>
                                <Typography className={classes.packageItems}>
                                    <div>{packageInfo?.[pakData]?.package}</div>
                                    <div>
                                        {packageInfo?.[pakData]?.weight} lb.
                                    </div>
                                </Typography>
                            </div>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={classes.deliverEstimates}>
                <Typography variant="h2" className={classes.deliveryTitle}>
                    Delivery rates and estimates
                </Typography>
                <Typography className={classes.helperText}>
                    Review the delivery quotes below and choose your rate at our
                    Ship Center.
                </Typography>
                <div>
                    {HolidayShippingEnabled?.isenabled &&
                    shipmentRateInfoBelowCutOffDate?.length ? (
                        <div>
                            <div className={classes.holidayDisAlign}>
                                <Heading
                                    type="h4"
                                    size={18}
                                    weight="normal"
                                    lineHeight={24}
                                >
                                    Get it there by 12/24!
                                </Heading>
                            </div>
                            <div className={classes.shipmentInfoRateContainer}>
                                {shipmentRateInfoBelowCutOffDate?.map(
                                    (shipmentRateData: any, i) => (
                                        <div
                                            className={
                                                classes.shipmentInfoRateHoliday
                                            }
                                            style={{
                                                borderBottom:
                                                    shipmentRateInfoBelowCutOffDate?.length -
                                                        1 ===
                                                    i
                                                        ? '2px solid #CC0000'
                                                        : 'none',
                                                borderRadius:
                                                    shipmentRateInfoBelowCutOffDate?.length -
                                                        1 ===
                                                    i
                                                        ? '0px 0px 10px 10px'
                                                        : 'none',
                                            }}
                                        >
                                            <div
                                                className={
                                                    classes.shipmentInfoRateAndType
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.serviceNameRate
                                                    }
                                                >
                                                    {
                                                        shipmentRateData.serviceName
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.serviceNameRate
                                                    }
                                                >
                                                    $
                                                    {shipmentRateData.retailRateTotal.toFixed(
                                                        2
                                                    )}
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    classes.shipmentInfoDeliveryAndDate
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.deliveryDate
                                                    }
                                                >
                                                    {' '}
                                                    Estimated Delivery{' '}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.deliveryDate
                                                    }
                                                >
                                                    {dateFormat(
                                                        shipmentRateData.deliveryDate
                                                    )}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                )}
                                {shipmentRateInfoAboveCutOffDate?.map(
                                    (shipmentRateData: any, i) => (
                                        <div
                                            className={classes.shipmentInfoRate}
                                        >
                                            <div
                                                className={
                                                    classes.shipmentInfoRateAndType
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.serviceNameRate
                                                    }
                                                >
                                                    {
                                                        shipmentRateData.serviceName
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.serviceNameRate
                                                    }
                                                >
                                                    $
                                                    {shipmentRateData.retailRateTotal.toFixed(
                                                        2
                                                    )}
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    classes.shipmentInfoDeliveryAndDate
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        classes.deliveryDate
                                                    }
                                                >
                                                    {' '}
                                                    Estimated Delivery{' '}
                                                </Typography>
                                                <Typography
                                                    className={
                                                        classes.deliveryDate
                                                    }
                                                >
                                                    {dateFormat(
                                                        shipmentRateData.deliveryDate
                                                    )}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={classes.shipmentInfoRateContainer}>
                            {shipmentRateInfo?.map(
                                (shipmentRateData: any, i) => (
                                    <div className={classes.shipmentInfoRate}>
                                        <div
                                            className={
                                                classes.shipmentInfoRateAndType
                                            }
                                        >
                                            <Typography
                                                className={
                                                    classes.serviceNameRate
                                                }
                                            >
                                                {shipmentRateData.serviceName}
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.serviceNameRate
                                                }
                                            >
                                                $
                                                {shipmentRateData.retailRateTotal.toFixed(
                                                    2
                                                )}
                                            </Typography>
                                        </div>
                                        <div
                                            className={
                                                classes.shipmentInfoDeliveryAndDate
                                            }
                                        >
                                            <Typography
                                                className={classes.deliveryDate}
                                            >
                                                {' '}
                                                Estimated Delivery{' '}
                                            </Typography>
                                            <Typography
                                                className={classes.deliveryDate}
                                            >
                                                {dateFormat(
                                                    shipmentRateData.deliveryDate
                                                )}
                                            </Typography>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Body
                color="textMediumGray"
                size={12}
                weight="light"
                textAlign="textCenter"
            >
                All pricing shown is an estimate; variances in price at time of
                purchase may occur. Actual price will be calculated in store
                when package is processed and will be based on: weight, package
                dimensions, and total travel distance.
            </Body>

            <div className={classes.nextBtnContainer}>
                <ButtonBase
                    className={classes.nextBtn}
                    onClick={handleNavigation}
                >
                    <span>Finalize label</span>
                </ButtonBase>
            </div>
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading}
            />
        </div>
    );
};

export default CreateShippingReviewInformation;
