import { atom, useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { useEffect } from 'react';
import { todayDate, parseDate } from 'src/lib/utils/app-utils';

// Atom to manage the visibility of the intro scree
export interface introShownState {
    isintroShownOn: boolean;
}
const introShownStateStorageKey = "introShown";
const introShownStateAtom = atomWithStorage<introShownState>(
    introShownStateStorageKey,
    {
        isintroShownOn: true,
    },
    createJSONStorage(() => sessionStorage)
);
function useIntroScreen() {
    const [showIntro, setShowIntro] = useAtom(introShownStateAtom);


    return { showIntro, setShowIntro };
};

export default useIntroScreen;