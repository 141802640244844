/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    ButtonBase,
    Dialog,
    makeStyles,
    Typography,
    SwipeableDrawerProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { eventMetricsMap, useEventsReporter, useRouter, useUserInfo } from 'src/hooks';
import COLORS from 'src/lib/colors';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import ROUTES from 'src/lib/routes';
import { addShipmentTracking } from 'src/lib/api-client/shipment-services';
import { Body, Button, Heading, SwipeableDrawer } from '../../UI';
import { IconScanShipping, IconCornerSplashRight } from '../../Icons';
import styles from './styles.module.scss';
import Brcd from '../../BarcodeScanner/Brcd';
import LoadingOverlay from '../../loading-overlay';

const useStyles = makeStyles(() => ({
    linkCta: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    paper: { borderRadius: '15px', margin: '24px', padding: '10px 0 0 0' },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '10px 20px 30px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px',
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    'MuiSwipeableDrawer-root': {
        position: 'relative',
    },
}));
interface ShippingRewardsInfoDialogProps extends SwipeableDrawerProps {
    points: string | undefined;
    hideBtn?: boolean;
}

function ShippingRewardsInfoDialog(props: ShippingRewardsInfoDialogProps) {
    const classes = useStyles();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);
    const [scannerData, setScannerData] = useState('');
    const { isRewardShipperEnable } = useUserInfo();
    const [scanMessage, setScanMessage] = useState({
        title: 'Scan successful!',
        description:
            'Visit Labels and tracking to view its current ' +
            'status or scan another package.',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [trackingError, setTrackingError] = useState(false);
    const [isScanCompleted, setIsScanCompleted] = useState(false);

    const { push } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const {
        updateFetchSenderInfo,
        updateReviewStage,
        updateShippingTab,
    } = useContext(ShippingInfoContext);

    const handleClose = () => {
        setIsScanCompleted(false);
        setTrackingError(false);
        setScannerData('');
    };

    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state);
        }
        push(ROUTES.shippingServicesLabelShipment);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'go to lables and tracking',
            element_location: 'scan successful',
        });
    };

    const handleStartShipment = () => {
        updateFetchSenderInfo(true);
        updateReviewStage(false);
        push({
            pathname: ROUTES.shippingServices,
            state: true,
            // search: '?step=createShipmentSenderInfo',
        }, { path: ROUTES.rewardsWallet });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'frequent shipper activated drawer',
        });
    };


    const handleScan = (elLoc: string) => {
        if (isRewardShipperEnable &&
            sessionStorage.getItem('startShipment') !== 'true') {
            push({
                pathname: ROUTES.shippingServices,
                state: true,
                search: '?step=scanToTrack',
            });
            analyticsCustomEvent(eventMetricsMap.shipping, {
                click_text: 'scan to track',
                element_location: 'stores page',
            });
        } else {
            setIsBarcodeScannerOpen(true);
            if (elLoc === 'scan_successful_modal') {
                analyticsCustomEvent(eventMetricsMap.shipping, {
                    click_text: 'scan another package',
                    element_location: 'scan successful',
                });
            }
            else {
                analyticsCustomEvent(eventMetricsMap.shipping, {
                    click_text: 'scan to track',
                    element_location: 'frequent shipper activated drawer',
                });
            }
        }
    };

    const getShipmentTrackingDetails = async (trackingnumber: any) => {
        setIsLoading(true);
        const response = await addShipmentTracking(trackingnumber);
        setScannerData('');
        setIsLoading(false);
        if (response?.issuccess) {
            setTrackingError(true);
            if (response?.status === 'SUCCESS') {
                setTrackingError(false);
                setIsScanCompleted(true);
                setScanMessage({
                    title: 'Scan successful!',
                    description:
                        'Visit Labels and tracking to view its current ' +
                        'status or scan another package.',
                });
                analyticsCustomEvent(eventMetricsMap.shipping, {
                    click_text: 'scan success',
                    element_location: 'scanner',
                });
            } else if (response?.status === 'Fail') {
                setScanMessage({
                    title: 'Your package couldn’t be found',
                    description:
                        'We couldn’t find your package at this time. Please try again later.',
                });
            } else if (response?.status === 'DUPLICATE_TRACKING') {
                setScanMessage({
                    title: 'Duplicate label',
                    description:
                        'This label has been previously scanned ' +
                        'and is already in the system.',
                });
            } else if (response?.status === 'INVALID_TRACKING') {
                setScanMessage({
                    title: `Your package couldn't be found`,
                    description:
                        'Make sure you have a UPS label and ' +
                        'shipment number to start tracking.',
                });
            } else if (response?.status === 'NONUS_TRACKING') {
                setScanMessage({
                    title: 'Want to ship internationally?',
                    description:
                        'Bring your package to your local Staples store ' +
                        'and we’ll ship it anywhere in the world.',
                });
            } else {
                setScanMessage({
                    title: 'Label couldn’t be scanned',
                    description:
                        'We couldn’t scan your label at this time. Please try again later.',
                });
            }
            setIsScanCompleted(true);
            setScannerData('');
        } else {
            setTrackingError(true);
            setIsScanCompleted(true);
            setScanMessage({
                title: 'Your package couldn’t be found',
                description:
                    'We couldn’t find your package at this time. Please try again later.',
            });
        }
    };

    useEffect(() => {
        if (scannerData) {
            getShipmentTrackingDetails(scannerData);
        }
    }, [scannerData]);

    return (
        <>
            {isLoading && (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            )}
            <SwipeableDrawer
                anchor="bottom"
                classes={{
                    root: classes['MuiSwipeableDrawer-root'],
                }}
                open={props.open}
                onClose={props.onClose}
                onOpen={props.onOpen}
                drawerAdornment={
                    <span className={styles.drawerAdornment}>
                        <IconCornerSplashRight height={82} width={114} />
                    </span>
                }
            >
                <div className={styles.content}>
                    <div>
                        <Heading
                            type="h1"
                            color="textPurple"
                            size={32}
                            weight="bold"
                            textAlign="textCenter"
                        >
                            {props.points} POINTS
                        </Heading>
                        <Body size={16}>when you ship or drop off a package.</Body>
                    </div>
                    {!props.hideBtn ? (
                        <>
                            <Button
                                bgColor="bgBlack"
                                color="textWhite"
                                variant="contained-rounded"
                                className={styles.startShipments}
                                onClick={handleStartShipment}
                            >
                                Start a shipment
                            </Button>
                            <Button
                                variant="icon"
                                color="textLinkBlue"
                                onClick={() => handleScan('drawer')}
                            >
                                <span className={styles.buttonContent}>
                                    <IconScanShipping /> Scan to track
                                </span>
                            </Button>
                        </>
                    ) : ''}

                    <Body size={10} weight="light">
                        Valid on purchases of [insert product name] made [insert channel – in Staples® U.S. stores, online at staples.com, etc.] only. Not valid on Instacart, Door Dash or Uber Eats orders. Offer limited to [insert product quantity limitation] [insert product name] purchase(s). While supplies last. Offer available to Staples Easy Rewards™ member only. To be eligible for the offer, member must activate offer in their Easy Rewards dashboard on staples.com or Staples mobile app and provide membership number at checkout. Points will be earned on the purchase amount paid at checkout after application of all promotions, coupons, instant savings and point redemptions. Taxes and shipping are not included in calculating the total purchase amount. May be used up to one time per Staples Easy Rewards member within the promotional period, nontransferable. Offer may not be combined with any other Staples Easy Rewards promotion in a single transaction. Not valid on prior purchases or purchases made with Staples Advantage In-Store Purchase Program. Offer is subject to change or cancellation at any time. Staples Easy Rewards program terms and conditions apply. For full program details visit staples.com/easy. Expires X/XX/XX. [Campaign ID].
                    </Body>
                </div>
            </SwipeableDrawer>
            {isBarcodeScannerOpen && (
                <Brcd
                    isScannerOpen={isBarcodeScannerOpen}
                    shippingScan
                    setScannerData={setScannerData}
                    onClose={() => setIsBarcodeScannerOpen(false)}
                />
            )}
            {isScanCompleted && (
                <Dialog
                    open={isScanCompleted}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    <div className={classes.closeButton}>
                        <Button
                            onClick={handleClose}
                            className={classes.closeIcon}
                            id="close-button"
                        >
                            <img
                                src={require('../../../assets/icons/iconCloseBlack.svg')}
                                alt="close icon"
                            />
                        </Button>
                    </div>
                    <div className={classes.dialogWrapper}>
                        <Typography
                            variant="h3"
                            className={classes.dialogTitle}
                        >
                            {scanMessage.title}
                        </Typography>
                        {!trackingError && (
                            <img
                                className={classes.successIcon}
                                src={require('../../../assets/icons/icon_success.svg')}
                                alt="scan successful"
                            />
                        )}

                        <Typography className={classes.dialogMessage}>
                            {scanMessage.description}
                        </Typography>
                        <ButtonBase
                            className={classes.startShipment}
                            onClick={() => handleSavedLabelShipment('shipped')}
                        >
                            <span>Go to labels and tracking</span>
                        </ButtonBase>

                        <ButtonBase
                            className={clsx(
                                classes.linkCta,
                                classes.anotherPackage
                            )}
                            onClick={() => handleScan('scan_successful_modal')}
                        >
                            <span>Scan another package </span>
                        </ButtonBase>
                    </div>
                </Dialog>
            )}
        </>
    );
}

export default ShippingRewardsInfoDialog;
