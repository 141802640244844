/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Box, Tab, Tabs, Typography, ButtonBase } from '@material-ui/core';
import { IconInfo, IconSmallCheckmark } from 'src/components/Icons';
import { getExpDate } from 'src/screens/FeaturedList/FeaturedList';
import { Body, Button, Heading } from 'src/components/UI';
import { motion } from 'framer-motion';
import IconBackToTop from 'src/components/Icons/IconBackToTop';
import { Player } from '@lottiefiles/react-lottie-player';
import {
    ActivateOfferMutateAsyncFunc,
    LoyaltyOffer,
    LoyaltyOfferResponse,
    updateLoyaltyOfferCache,
} from 'src/services/easyRewardsServices';
import LoadingOverlay from 'src/components/loading-overlay';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import { useSetAtom } from 'jotai';
import CouponList from 'src/screens/CouponTab/CouponList';
import { format } from 'date-fns';
import { mergeClasses } from 'src/stylesheets/utils';
import ROUTES from 'src/lib/routes';
import { makeSearchString } from 'src/lib/utils/url-utils';
import ShippingRewards from 'src/components/shipping-services/shipping-rewards/shipping-rewards';
import OfferCard, { toastAtom } from '../OfferList/OfferCard';
import OfferList from '../OfferList';
import { initialInfoDrawerState } from '../OfferList/OfferList';
import styles from './styles.module.scss';
import OfferTags from '../OfferList/OfferTags';

// TODO fix types DO NOT USE ANY
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className={styles.tablePanelBox}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `offer-tab-${index}`,
        'aria-controls': `offer-tabpanel-${index}`,
    };
}

interface OfferTabProps {
    activateAllOffers: () => void;
    targetedOffers?: LoyaltyOffer[];
    isTargetedOffersLoading?: boolean;
    massOffers?: LoyaltyOffer[];
    isMassOffersLoading?: boolean;
    handleActivate: ActivateOfferMutateAsyncFunc;
    isMassOffersFetched?: boolean;
    isTargetedOffersFetched?: boolean;
    refetchMassLoyaltyOffers?: any;
    couponData?: any;
    handleviewAllTargetedOffers?: () => void;
    handleAllMassOffers?: () => void;
    showBackToTop: boolean;
    hideHeader?: boolean;
    totalTargetOffers?: number;
    totalMassOffers?: number;
    hideMassOfferData?: boolean;
    hideTargetOfferData?: boolean;
    setShowBackToTop: (v: boolean) => void;
    showActivateAll: boolean;
    toggleIndex?: (v: number, flag: boolean) => void;
    allTargetedOffer?: LoyaltyOffer[];
    allMassOffer?: LoyaltyOffer[];
    isMainRewardsPage?: boolean;
    featuredOffers?: LoyaltyOffer[] | undefined;
    isFeaturedOfferFetched?: boolean;
    startIndex?: number;
    deeplinkActiveOffer: boolean;
    setDeeplinkActiveOffer: (v: boolean) => void;
}

export const initialScrollToOffer: {
    offerId: string;
    isScroll: boolean;
} = {
    offerId: '',
    isScroll: false,
};

function OfferTab({
    activateAllOffers,
    targetedOffers,
    isTargetedOffersLoading,
    massOffers,
    isMassOffersLoading,
    handleActivate,
    isMassOffersFetched,
    isTargetedOffersFetched,
    refetchMassLoyaltyOffers,
    couponData,
    handleviewAllTargetedOffers,
    handleAllMassOffers,
    showBackToTop,
    hideHeader = false,
    hideMassOfferData = false,
    hideTargetOfferData = false,
    totalTargetOffers,
    totalMassOffers,
    setShowBackToTop,
    showActivateAll,
    toggleIndex,
    allTargetedOffer,
    allMassOffer,
    isMainRewardsPage = false,
    featuredOffers,
    isFeaturedOfferFetched,
    startIndex = 10,
    deeplinkActiveOffer,
    setDeeplinkActiveOffer,
}: OfferTabProps) {
    const offerRef = useRef<any>(null);

    const { query, push } = useRouter();
    const setToastMessage = useSetAtom(toastAtom);
    const [scrollToOffer, setScrollToOffer] = useState(initialScrollToOffer);
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo, isRewardShipperEnable } = useUserInfo();
    const dynamicTabIndx = Math.max(startIndex + (featuredOffers?.length || 0));
    const [infoDrawerData, setinfoDrawerData] = useState(
        initialInfoDrawerState
    );

    const executeScroll = () => {
        setShowBackToTop(false);
    };

    const handleViewAll = () => {
        handleviewAllTargetedOffers?.();
        analyticsCustomEvent(eventMetricsMap.easy_rewards, {
            click_text: 'view all',
            element_location: 'Offers - For You',
            event_rewards_number:
                userInfo?.rewardsNumber?.padStart(10, '0') || '',
        });
    };

    const handleViewMassAll = () => {
        handleAllMassOffers?.();
        analyticsCustomEvent(eventMetricsMap.easy_rewards, {
            click_text: 'view all',
            element_location: 'Offers - More Offers',
            event_rewards_number:
                userInfo?.rewardsNumber?.padStart(10, '0') || '',
        });
    };
    const offerUpdate = (
        offerId: string,
        isTargeted: boolean,
        isActive: boolean
    ) => {
        updateLoyaltyOfferCache({
            offerType: isTargeted ? 'TARGETED' : 'MASS',
            updater: (oldData) => {
                if (oldData) {
                    const newData: typeof oldData = {
                        ...oldData,
                    };
                    newData.offers.map((item) => {
                        if (item.offerid === offerId) {
                            item.activated = isActive;
                        }
                        return item;
                    });
                    return newData;
                }
                return {} as LoyaltyOfferResponse;
            },
        });
    };
    const activateOffer = (offerId: string, isTargeted: boolean) => {
        offerUpdate(offerId, isTargeted, true);
        handleActivate([offerId])
            .then((res) => {
                console.log('🚀 ~ .then ~ res:', res);
                if (!res?.data[0].success) {
                    offerUpdate(offerId, isTargeted, false);
                }
            })
            .catch(() => {
                setScrollToOffer({
                    offerId,
                    isScroll: true,
                });
                offerUpdate(offerId, isTargeted, false);
            });
    };

    useEffect(() => {
        const isActivateOffer = localStorage.getItem('isActivateOffer');
        const findFeaturedOffers = featuredOffers?.find(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const findMassOfferIndex = allMassOffer?.findIndex(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const findAllMassOffer = allMassOffer?.find(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const findTargetOfferIndex = allTargetedOffer?.findIndex(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const findAllTargetOffer = allTargetedOffer?.find(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const offerId =
            findAllMassOffer?.offerid || findAllTargetOffer?.offerid;
        const isTargeted = !!findAllTargetOffer?.offerid;
        if (isActivateOffer === 'false') {
            setScrollToOffer({
                offerId: '',
                isScroll: false,
            });
            if (offerId) {
                const offer = findAllTargetOffer?.offerid
                    ? findAllTargetOffer
                    : findAllMassOffer || null;
                if (findAllTargetOffer?.offerid) {
                    if (findTargetOfferIndex && findTargetOfferIndex > 3) {
                        handleviewAllTargetedOffers?.();
                    }
                } else if (findMassOfferIndex && findMassOfferIndex > 3) {
                    handleAllMassOffers?.();
                }
                setTimeout(() => {
                    localStorage.setItem('isActivateOffer', 'true');
                    setinfoDrawerData({
                        offer,
                        isOpen: true,
                    });

                    setScrollToOffer({
                        offerId,
                        isScroll: true,
                    });
                }, 2000);
                if (
                    query.shouldActivate === 'true' &&
                    !offer?.activated &&
                    !deeplinkActiveOffer
                ) {
                    console.log(
                        '🚀 ~ setTimeout ~ active offerId time:',
                        offerId
                    );
                    setDeeplinkActiveOffer(true);
                    setTimeout(() => {
                        console.log(
                            '🚀 ~ setTimeout ~ active offerId:',
                            offerId
                        );
                        activateOffer(offerId, isTargeted);
                    }, 2500);
                }
            } else if (
                !offerId &&
                !findFeaturedOffers?.offerid &&
                isMassOffersFetched &&
                isTargetedOffersFetched &&
                isFeaturedOfferFetched
            ) {
                localStorage.setItem('isActivateOffer', 'true');
                setToastMessage('This offer does not exist');
            }
        }
    }, [
        allMassOffer?.length,
        allTargetedOffer?.length,
        isMassOffersFetched,
        isTargetedOffersFetched,
        featuredOffers?.length,
        isFeaturedOfferFetched,
    ]);

    const handleCouponNavigation = (e: any) => {
        e.preventDefault();
        push({
            pathname: ROUTES.coupons,
            search: makeSearchString({
                couponNumber: couponData?.couponNumber,
                deepLink: false,
                tabIndex: 1,
            }),
        });
    };
    if (isTargetedOffersLoading || isMassOffersLoading) {
        return (
            <Player
                autoplay
                loop
                src={require('../../../assets/lotties/stapleRevealWordmark.json')}
                style={{ height: '100px' }}
            />
        );
    }

    if (!targetedOffers?.length && !massOffers?.length) {
        return (
            <Body weight="bold" size={18}>
                Offers are unavailable at this time, please check back later.
            </Body>
        );
    }

    return (
        <div ref={offerRef}>
            <Box sx={{ borderBottom: 4, borderColor: 'divider' }}>
                {showBackToTop ? (
                    <motion.div
                        className={styles.topBtn}
                        initial={{ top: 0, opacity: 0 }}
                        animate={{ top: '4rem', opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div onClick={executeScroll}>
                            <IconBackToTop />
                        </div>
                    </motion.div>
                ) : null}
                <>
                    {!hideTargetOfferData && (
                        <>
                            <section
                                className={mergeClasses(
                                    styles.px4,
                                    styles.listContainer
                                )}
                                id="your-offer-id"
                            >
                                {!hideHeader && (
                                    <div className={styles.offerHeading}>
                                        <p
                                            className={styles.heading}
                                            tabIndex={dynamicTabIndx}
                                            aria-roledescription="These offers are personalized for you"
                                        >
                                            Your offers
                                        </p>
                                        {targetedOffers?.length ||
                                        massOffers?.length ? (
                                            <>
                                                {showActivateAll ? (
                                                    <Typography
                                                        tabIndex={
                                                            dynamicTabIndx + 1
                                                        }
                                                        aria-selected="true"
                                                        aria-label="Activate all"
                                                        className={
                                                            styles.activeAll
                                                        }
                                                        onClick={() => {
                                                            activateAllOffers();
                                                            analyticsCustomEvent(
                                                                eventMetricsMap.easy_rewards,
                                                                {
                                                                    click_text:
                                                                        'activate all',
                                                                    element_location:
                                                                        'Offers - For You',
                                                                    event_rewards_number:
                                                                        userInfo?.rewardsNumber ||
                                                                        '',
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Activate all
                                                    </Typography>
                                                ) : (
                                                    <Body
                                                        color="textPurple"
                                                        weight="bold"
                                                        size={12}
                                                    >
                                                        <IconSmallCheckmark />
                                                        All offers are activated
                                                    </Body>
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                )}
                                <OfferList
                                    variant="vertical"
                                    data={targetedOffers}
                                    isDataLoading={isTargetedOffersLoading}
                                    activateOffer={handleActivate}
                                    infoDrawerData={infoDrawerData}
                                    setinfoDrawerData={setinfoDrawerData}
                                    scrollToOffer={scrollToOffer}
                                    setScrollToOffer={setScrollToOffer}
                                    offerCacheKey="TARGETED"
                                    strtIndex={dynamicTabIndx + 2}
                                />
                            </section>
                            <div
                                className={styles.offerViewAll}
                                style={{ borderBottom: '0px none' }}
                                id="view-all-id-for-offer"
                            >
                                {totalTargetOffers && totalTargetOffers > 3 && (
                                    <Button
                                        variant="text"
                                        fontWeight="normal"
                                        fontSize={16}
                                        color="#000"
                                        className={styles.viewAllBtn}
                                        onClick={handleViewAll}
                                        tabIndex={dynamicTabIndx + 3}
                                        ariaLabel={`View all ${totalTargetOffers} button`}
                                        ariaDescription={'View all of your offers'}
                                    >
                                        <img
                                            src={require('../../../assets/icons/view-all.svg')}
                                            alt=""
                                        />
                                        <span>
                                            View all {totalTargetOffers}
                                        </span>
                                    </Button>
                                )}
                            </div>
                            {isRewardShipperEnable ? <ShippingRewards noMargin /> : null}
                        </>
                    )}
                    {!hideMassOfferData && (
                        <>
                            <section
                                className={mergeClasses(
                                    styles.px4,
                                    styles.listContainer
                                )}
                            >
                                {!hideHeader && (
                                    <div
                                        className={styles.offerHeading}
                                        id="more-offer-id"
                                    >
                                        <p
                                            className={styles.heading}
                                            tabIndex={dynamicTabIndx + 4}
                                        >
                                            More offers
                                        </p>
                                        {targetedOffers?.length ||
                                        massOffers?.length ? (
                                            <>
                                                {showActivateAll ? (
                                                    <Typography
                                                        className={
                                                            styles.activeAll
                                                        }
                                                        onClick={() => {
                                                            activateAllOffers();
                                                            analyticsCustomEvent(
                                                                eventMetricsMap.easy_rewards,
                                                                {
                                                                    click_text:
                                                                        'activate all',
                                                                    element_location:
                                                                        'Offers - More Offers',
                                                                    event_rewards_number:
                                                                        userInfo?.rewardsNumber ||
                                                                        '',
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Activate all
                                                    </Typography>
                                                ) : (
                                                    <Body
                                                        color="textPurple"
                                                        weight="bold"
                                                        size={12}
                                                        className={
                                                            styles.allOffersActivatedText
                                                        }
                                                    >
                                                        <IconSmallCheckmark />
                                                        All offers are activated
                                                    </Body>
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                )}
                                <OfferList
                                    variant="vertical"
                                    data={massOffers}
                                    isDataLoading={isMassOffersLoading}
                                    activateOffer={handleActivate}
                                    infoDrawerData={infoDrawerData}
                                    setinfoDrawerData={setinfoDrawerData}
                                    scrollToOffer={scrollToOffer}
                                    setScrollToOffer={setScrollToOffer}
                                    offerCacheKey="MASS"
                                    isMainRewardsPage={isMainRewardsPage}
                                    strtIndex={dynamicTabIndx + 7}
                                />
                            </section>
                            {!hideHeader && (
                                <div className={styles.offerViewAll}>
                                    {totalMassOffers && totalMassOffers > 3 && (
                                        <Button
                                            variant="text"
                                            fontWeight="normal"
                                            fontSize={16}
                                            color="#000"
                                            className={styles.viewAllBtn}
                                            onClick={handleViewMassAll}
                                            ariaLabel={`View all ${totalMassOffers}`}
                                            tabIndex={dynamicTabIndx + 10}
                                            ariaDescription={'View all offers'}
                                        >
                                            <img
                                                src={require('../../../assets/icons/view-all.svg')}
                                                alt="view all icon"
                                            />
                                            <span>
                                                View all {totalMassOffers}
                                            </span>
                                        </Button>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    {couponData?.couponInfo &&
                    Object?.keys?.(couponData?.couponInfo)?.length ? (
                        <section
                            className={mergeClasses(
                                styles.px4,
                                styles.listContainer
                            )}
                            style={{ marginBottom: '25px' }}
                        >
                            <div className={styles.offerHeading}>
                                <p
                                    className={styles.heading}
                                    tabIndex={dynamicTabIndx + 11}
                                >
                                    Available coupons
                                </p>
                            </div>

                            <Box
                                className={styles.CardBox}
                                onClick={handleCouponNavigation}
                                tabIndex={dynamicTabIndx + 12}
                            >
                                <div className={styles.CardDetailsSection}>
                                    <img
                                        className={styles.CardImg}
                                        src={couponData?.couponInfo?.imageURL}
                                        alt="product"
                                    />
                                    <div className={styles.CardTextSection}>
                                        <Heading
                                            textAlign={'textLeft'}
                                            size={20}
                                            type="h3"
                                            weight="bold"
                                            color="#CC0000"
                                            lineHeight={26}
                                            tabIndex={dynamicTabIndx + 13}
                                            ariaLabel={
                                                couponData?.couponInfo
                                                    ?.descriptionText1
                                            }
                                        >
                                            {
                                                couponData?.couponInfo
                                                    ?.descriptionText1
                                            }
                                        </Heading>
                                        <Body
                                            size={16}
                                            textAlign={'textLeft'}
                                            weight="light"
                                            color="Black"
                                            lineHeight={20}
                                            tabIndex={dynamicTabIndx + 14}
                                            ariaLabel={
                                                couponData?.couponInfo
                                                    ?.descriptionText2
                                            }
                                        >
                                            {
                                                couponData?.couponInfo
                                                    ?.descriptionText2
                                            }
                                        </Body>
                                    </div>
                                </div>
                                <div className={styles.BottomSection}>
                                    {couponData?.couponInfo?.expirationDate && (
                                        <Body
                                            size={12}
                                            textAlign={'textLeft'}
                                            weight="light"
                                            color="#585858"
                                           
                                        >
                                            {getExpDate(
                                                couponData?.couponInfo
                                                    ?.expirationDate,
                                                couponData?.couponInfo
                                                    ?.validForRetail &&
                                                    couponData?.couponInfo
                                                        ?.validForDotcom
                                                    ? ''
                                                    : couponData?.couponInfo
                                                          ?.validForRetail
                                                    ? 'retail'
                                                    : 'dotcom',
                                                'M/d/yy'
                                            )}
                                        </Body>
                                    )}
                                    <Button
                                        tabIndex={dynamicTabIndx + 16}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            toggleIndex?.(2, true);
                                            analyticsCustomEvent(
                                                eventMetricsMap.easy_rewards,
                                                {
                                                    click_text:
                                                        'see all coupons',
                                                    element_location:
                                                        'available coupons',
                                                    event_rewards_number:
                                                        userInfo?.rewardsNumber ||
                                                        '',
                                                }
                                            );
                                        }}
                                        className={styles.seeAllButton}
                                        fontSize={12}
                                        fontWeight="bold"
                                    >
                                        See all coupons
                                    </Button>
                                </div>
                            </Box>
                        </section>
                    ) : null}
                </>
            </Box>
        </div>
    );
}
export default OfferTab;
