/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

// TODO change this so the color can be set using props. Refer IconEasyRewardsHorizontal component. This component clashes with IconCheckmark remove the one which is not needed
function IconSmallCheckmark({
    height = 12,
    width = 12,
    fillColor = 'fillWhite',
    bgFillColor = 'fillPurple',
}: IconInterface) {
    return (
        <svg
            style={{ verticalAlign: 'middle', marginRight: '4px' }}
            width={width}
            height={height}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="6"
                cy="6"
                r="6"
                className={color_classes[bgFillColor]}
            />
            <path
                d="M8.11452 3.91176C8.28407 3.71612 8.58011 3.69498 8.77575 3.86453C8.9536 4.01867 8.98724 4.27734 8.8645 4.47008L8.82298 4.52576L5.63206 8.20529C5.41802 8.41932 5.08123 8.43579 4.8483 8.25468L4.79237 8.20529L3.13729 6.55021C2.95424 6.36716 2.95424 6.07036 3.13729 5.8873C3.30371 5.72088 3.56412 5.70576 3.74763 5.84191L3.80021 5.8873L5.19375 7.28063L8.11452 3.91176Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconSmallCheckmark;
