import { ButtonBase, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import FullWidthCard from 'src/components/cards/full-width-card';
import { IconCornerSplash } from 'src/components/Icons';
import { eventMetricsMap, useEventsReporter, useRouter } from 'src/hooks';
import useConfigInfo from 'src/hooks/useConfigInfo';
import styles from './style.module.scss';
import ShippingRewardsInfoDialog from '../ShippingRewardsInfoDialog';
import chevronBlue from '../../../assets/icons/iconChevronBlue.svg';

function ShippingRewards({ noMargin }: { noMargin?: boolean }) {
    const { shortdesc: shippingConfigPoints } = useConfigInfo('ShippingOfferPoints');
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const router = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const handleOpen = () => {
        setOpenInfoDialog(true);
        analyticsCustomEvent(eventMetricsMap.easy_rewards, {
            click_text: `${shippingConfigPoints} points when you ship a package`,
            element_location: router.pathname === '/wallet' ? 'banner offers - easy rewards' : 'banner offers - stores page',

        });
    };

    const handleClose = () => {
        setOpenInfoDialog(false);
    };

    return (
        <>
            <FullWidthCard
                noPadding
                hasManualShadow={false}
                id="shipping-rewards-container"
                className={
                    !noMargin
                        ? styles.rewardingShipping
                        : styles.rewardingShippingFullWidth
                }
                elevation={0}
                onClick={handleOpen}
            >
                <div className={styles.cardHeader}>
                    <div className={styles.tileAdorment} aria-hidden="true">
                        <IconCornerSplash height={55} width={87} />
                    </div>

                    <div className={styles.description}>
                        <div className={styles.helperTextDsc}>
                            <h2 className={styles.pointsHighlighted}>
                                {shippingConfigPoints}  POINTS
                            </h2>

                            <Typography>when you ship or drop off a package.</Typography>
                        </div>
                        <ButtonBase
                            id="shipping_rewards_learn-more"
                            className={styles.learnMore}
                        >
                            Learn more{' '}
                            <img
                                aria-label="Learn More"
                                aria-hidden="true"
                                src={chevronBlue}
                                alt="learn more icon"
                            />
                        </ButtonBase>
                    </div>
                </div>
            </FullWidthCard>
            <ShippingRewardsInfoDialog
                points={shippingConfigPoints}
                onClose={handleClose}
                onOpen={handleOpen}
                open={openInfoDialog}
            />
        </>
    );
}

export default ShippingRewards;
