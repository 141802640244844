import { ButtonBase, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import ValidationTextField from 'src/components/validation-text-field';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import { validateZipCode } from 'src/lib/utils/user-utils';
import useStoreData from 'src/hooks/useStoreData';
import { storeCommunity } from 'src/lib/utils/store-utils';
import { Store } from 'src/services/types';
import StoreSearchModal from 'src/components/store-search-modal';
import { getShipmentRates } from 'src/lib/api-client/shipment-services';
import LoadingOverlay from 'src/components/loading-overlay';
import InfoButton from 'src/components/info-button';
import { Body } from 'src/components/UI';
import ShippingErrorInline from '../shipping-errors/shipping-error-inline';

const useStyles = makeStyles((theme) => ({
    mainConatiner: {
        display: 'flex',
        padding: '0 24px 24px 24px',
        gap: '24px',
        flexDirection: 'column',
        '& .MuiFormLabel-root': {
            color: '#444444 !important',
        },
        '& .MuiInputBase-input': {
            padding: '6px 0 0px',
            fontSize: '18px',
        },
    },
    chevron: {
        position: 'absolute',
        left: '55px',
        top: '-5px',
    },
    infoContainer: {
        position: 'relative',
    },
    tooltip: {
        '& .MuiTooltip-arrow': {
            overflow: 'unset',
            left: 'auto !important',
            right: '85% !important',
        },
    },
    title: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '0.200px',
        marginBottom: '20px',
    },
    subHeaderText: {
        fontSize: '16px',
        fontWeight: 400,
        marginTop: 16,
        lineHeight: '22px',
        color: '#1E1E1E',
        maxWidth: 327,
    },
    locationContainer: {
        display: 'flex',
        gap: '5px',
        flexDirection: 'column',
        marginBottom: '6px',
        alignItems: 'flex-start',
    },
    location: {
        display: 'flex',
        gap: '10px',
    },
    locationText: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '25.96px',
        color: '#343434',
        letterSpacing: '-0.75px',
    },
    changeLocation: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        color: '#086DD2',
    },
    getQuoteBtn: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        marginTop: '16px',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    getQuoteBtnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    formConatiner: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
}));

const getContryCode = (countryCode: string) => {
    switch (countryCode) {
        case 'CA':
            return 1;
        case 'GU':
            return 1;
        case 'MX':
            return 52;
        case 'PR':
            return 1;
        case 'US':
            return 1;
        case 'VI':
            return 1;
        default:
            return 1;
    }
};

const validationSchema = yup.object({
    recipientZipCode: yup
        .string()
        .required('Zip code is a required field.')
        .matches(/^(?:\d{5}|\d{5}-\d{4})$/, 'Zip code is invalid'),
    weight: yup
        .number()
        .required('Weight is a required field.')
        .max(150, 'Weight must be less than or equal to 150.')
        .min(1, 'Weight must be greater than 0.'),
    country: yup.string().required('Country is a required field.'),
});

const ShippingQuoteEstimator = ({
    setShipmentRateInfo,
    initialShipmentValues,
    setInitialShipmentValues,
}: {
    setShipmentRateInfo: (data: any) => void;
    initialShipmentValues: any;
    setInitialShipmentValues: (data: any) => void;
}) => {
    const classes = useStyles();
    const { history } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const {
        mainStore,
        additionalStores,
        isFetching,
        updateMainStore,
        storeFindErrorMessage,
    } = useStoreData();
    const [error, setError] = useState(false);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const { analyticsCustomEvent } = useEventsReporter();

    console.log('ShippingQuoteEstimator');
    const [activeField, setActiveField] = useState('');

    const handleNavigation = () => {
        history.push({
            pathname: ROUTES.getQuoteShippingInformation,
            search: '?step=shippingRateDelivery',
        });
    };

    const {
        values,
        handleSubmit,
        handleChange,
        errors,
        touched,
        setFieldError,
    } = useFormik({
        initialValues: {
            recipientZipCode: initialShipmentValues.recipientZipCode,
            weight: initialShipmentValues.weight,
            country: initialShipmentValues.country,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (currentValues) => {
            setError(false);
            setIsLoading(true);
            const bodyData = {
                recipientZipCode: currentValues.recipientZipCode,
                country: currentValues.country,
                recipientcountry: getContryCode(currentValues.country),
                weight: currentValues.weight,
            };
            getShipmentRates(
                mainStore?.address?.postal_code as unknown as string,
                currentValues.recipientZipCode,
                getContryCode(currentValues.country),
                currentValues.country,
                currentValues.weight
            ).then((responseData: any) => {
                if (
                    responseData?.data?.succeeded === 'true' &&
                    responseData?.data?.data?.length
                ) {
                    setShipmentRateInfo(responseData.data);
                    handleNavigation();
                    setInitialShipmentValues(bodyData);
                } else {
                    setError(true);
                }
                setIsLoading(false);
                console.log({ responseData });
            });
            analyticsCustomEvent(eventMetricsMap.shipping, {
                click_text: 'get quote',
                element_location: 'quote estimator',
            });
        },
    });
    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [mainStore]);

    const onStoreSelected = (store: Store) => {
        updateMainStore(store);
        setSearchModalOpen(false);
    };
    const changeLabel = (e: any) => {
        const fieldName = e.type === 'focus' ? e?.target?.name : '';
        setFieldError(fieldName, '');
        setActiveField(fieldName);
    };
    const handleKeyDown = (event: any) => {
        if (event.key === '.') {
            event.preventDefault();
        }
    };
    const handleZipChange = (e: any) => {
        const zipStatus = validateZipCode(e);
        if (zipStatus) {
            if (e.target.value.length === 7 && !e.target.value.includes('-')) {
                // eslint-disable-next-line
                e.target.value = `${e.target.value.slice(
                    0,
                    5
                )}-${e.target.value.slice(5)}`;
                handleChange({
                    target: { value: e.target.value, name: e.target.name },
                });
            } else {
                handleChange({
                    target: { value: e.target.value, name: e.target.name },
                });
            }
        } else {
            e.preventDefault();
        }
    };
    const handleChangeLocation = () => setSearchModalOpen(true);

    return (
        <>
            {!error ? (
                <div className={classes.mainConatiner} id="get-quote-conatiner">
                    <Typography
                        className={classes.subHeaderText}
                        id="subHeaderText"
                    >
                        Enter your details below and get a quote based on your
                        nearest Staples<sup>&reg;</sup> store.
                    </Typography>
                    <div
                        className={classes.locationContainer}
                        id="locationContainer"
                    >
                        <Typography className={classes.title}>
                            Your store
                        </Typography>
                        <div className={classes.location} id="location">
                            <img
                                src={require('../../../assets/icons/locationRed.svg')}
                                alt="location"
                            />
                            <Typography className={classes.locationText}>
                                {storeCommunity(mainStore) || 'Unknown'}
                            </Typography>
                        </div>
                        <ButtonBase
                            className={classes.changeLocation}
                            onClick={handleChangeLocation}
                        >
                            Change location
                        </ButtonBase>
                    </div>
                    <form
                        onSubmit={handleSubmit}
                        className={classes.formConatiner}
                        id="formConatiner"
                    >
                        <div className={classes.fieldContainer}>
                            <Typography className={classes.title}>
                                Destination
                            </Typography>
                            <div className={classes.infoContainer}>
                                <ValidationTextField
                                    fullWidth
                                    disabled
                                    id="sender-country-input"
                                    name="country"
                                    label="Country*"
                                    value="United States"
                                    onChange={handleChange}
                                    onBlur={(e: any) => changeLabel(e)}
                                    onFocus={(e: any) => changeLabel(e)}
                                    error={
                                        touched.country &&
                                        Boolean(errors.country)
                                    }
                                    helperText={
                                        touched.country && errors.country
                                    }
                                    style={{ marginBottom: '24px' }}
                                />
                                <div className={classes.chevron}>
                                    <InfoButton
                                        tooltipTitle="At this time, only domestic shipping is
                            available when starting a shipment in the app"
                                        tooltipCta="Got it"
                                        buttonId="country-tooltip-button"
                                        tooltipPlacement="top-start"
                                        buttonSize="13px"
                                        customizedIcon={require('src/assets/icons/inforIcon.svg')}
                                        tooltipClasses={{
                                            tooltip: classes.tooltip,
                                        }}
                                    />
                                </div>
                            </div>
                            <ValidationTextField
                                fullWidth
                                id="sender-zipCode-input"
                                name="recipientZipCode"
                                label="Recipient’s zip code*"
                                type="text"
                                inputProps={{ maxLength: 10 }}
                                value={values.recipientZipCode}
                                onChange={handleZipChange}
                                onBlur={(e: any) => changeLabel(e)}
                                onFocus={(e: any) => changeLabel(e)}
                                error={
                                    touched.recipientZipCode &&
                                    Boolean(errors.recipientZipCode)
                                }
                                helperText={
                                    touched.recipientZipCode &&
                                    errors.recipientZipCode
                                }
                                style={{ marginBottom: '24px', width: '50%' }}
                            />
                        </div>
                        <div className={classes.fieldContainer}>
                            <Typography className={classes.title}>
                                Estimated shipment weight
                            </Typography>
                            <ValidationTextField
                                fullWidth
                                id="estimated-weight-input"
                                name="weight"
                                label="Weight (lb.)*"
                                type="number"
                                onChange={handleChange}
                                value={values.weight}
                                onBlur={(e: any) => changeLabel(e)}
                                onFocus={(e: any) => changeLabel(e)}
                                onKeyDown={handleKeyDown}
                                error={touched.weight && Boolean(errors.weight)}
                                helperText={touched.weight && errors.weight}
                                style={{ width: '50%' }}
                            />
                        </div>

                        <div className={classes.getQuoteBtnContainer}>
                            <ButtonBase
                                className={classes.getQuoteBtn}
                                type="submit"
                            >
                                <span>Get quote</span>
                            </ButtonBase>
                        </div>

                        <Body
                            color="textMediumGray"
                            size={12}
                            weight="light"
                            textAlign="textCenter"
                        >
                            All pricing shown is an estimate; variances in price
                            at time of purchase may occur. Actual price will be
                            calculated in store when package is processed and
                            will be based on: weight, package dimensions, and
                            total travel distance.
                        </Body>
                    </form>
                    <LoadingOverlay
                        variant="wordmark"
                        theme="light"
                        open={isLoading}
                    />
                    {searchModalOpen ? (
                        <StoreSearchModal
                            mainStore={mainStore}
                            nearbyStores={additionalStores}
                            onStoreSelected={onStoreSelected}
                            open={searchModalOpen}
                            ignoreIsActiveScreen
                            handleClose={() => setSearchModalOpen(false)}
                        />
                    ) : null}
                </div>
            ) : (
                <ShippingErrorInline setError={setError} />
            )}
        </>
    );
};

export default ShippingQuoteEstimator;
