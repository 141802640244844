/* eslint-disable no-useless-computed-key */
import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
    bold: {
        fontWeight: 700,
    },
    carouselContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: '#101820',
        height: '100vh',
        overflow: 'hidden',
    },
    couponContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '60vw',
        height: '100%',
        overflow: 'auto',
        borderRadius: '15px',
        justifyContent: 'space-between',
        background: 'white',
        ['@media (max-width: 900px)']: {
            width: '90vw',
        },
    },
    couponNumberContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
    },
    couponNumber: {
        color: COLORS.brandRed,
        fontSize: 16,
        letterSpacing: '0.05rem',
        fontWeight: 'bold',
    },
    promoContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    couponPromoContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        alignItems: 'center',
        padding: '0 1rem',
        overflow: 'auto',
        width: '100%',
        marginTop: '0.5rem',
    },
    barcodeAndTCContainer: {
        position: 'sticky',
    },
    barcodeContainer: {
        margin: '1rem auto',
        padding: '0',
        fontSize: '0.75rem',
        maxWidth: 315,
        display: 'flex',
        flexDirection: 'column',
        color: '#585858',
        '& p': {
            margin: 0,
            marginBottom: '0.5rem',
        },
        '& canvas': {
            marginBottom: '0.5rem',
            marginTop: '1rem',
        },
    },
    saveButton: {
        alignSelf: 'flex-end',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1rem',
        borderRadius: '0 15px 0 8px',
        background: COLORS.purple,
        color: COLORS.whiteSmoke,
        padding: '0.8rem',
        border: 'none',
        minWidth: '95px',
    },
    savedMessage: {
        padding: '10px 20px 0px',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#5B0069',
        '& p': {
            margin: 0,
        },
    },
    promoImg: {
        maxHeight: '50%',
        ['@media (max-height: 700px)']: {
            maxHeight: '40%',
        },
    },
    promoText1Primary: {
        fontSize: 64,
        fontWeight: 'bold',
        color: COLORS.brandRed,
        margin: '1rem 0',
        ['@media (max-height: 700px)']: {
            fontSize: 32,
        },
    },
    promoText1Secondary: {
        fontSize: 36,
        fontWeight: 'bold',
        color: COLORS.brandRed,
        lineHeight: '2.625rem',
        margin: '0',
        marginTop: '1rem',
        alignSelf: 'flex-start',
        ['@media (max-height: 700px)']: {
            fontSize: 24,
        },
    },
    promoText2: {
        // fontSize: 30,
        lineHeight: '2rem',
        margin: '0',
        alignSelf: 'flex-start',
        ['@media (max-height: 700px)']: {
            fontSize: 16,
        },
    },
    notificationSwitchContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        marginTop: '1rem',
    },
    tncText: {
        fontSize: 10,
        color: '#909090',
    },
    closeButton: {
        color: COLORS.primaryWhite,
        padding: '1rem 1rem 0 1rem',
        alignSelf: 'flex-end',
    },
    renderedCardNumber: {
        height: '69px',
    },
    rewardsSignInButton: {
        width: '100%',
        padding: '.5rem',
        minHeight: '2.5rem',
        textTransform: 'none',
        borderRadius: '1.885rem',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        fontWeight: 500,
        fontSize: '1rem',
        marginBottom: '2rem',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.brandRed,
        },
    },
    joinNowButton: {
        fontWeight: 'bold',
        color: COLORS.brandCyan,
        paddingRight: '0.5rem',
    },
    limitedTimeBtn: {
        height: '40px',
        width: '200px',
        borderRadius: '75px',
        background: '#086DD2',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0px',
        textlign: 'center',
        color: '#FFFFFF',
        marginTop: '1.25rem',
        marginLeft: '1.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
    },
    infoText: {
        textAlign: 'center',
        fontSize: 10,
    },
    infoCopied: {
        position: 'absolute',
        left: 20,
        fontSize: 12,
    },
    clipBtnAlign: {
        height: '46px',
    },
    clipContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    unclipText: {
        color: '#585858',
        textAlign: 'center',
        textDecoration: 'underline',
    },
});

export default useStyles;
