import React from 'react';


function IconERGift() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" width={100} data-name="Layer 1" viewBox="0 0 1080 1080">
  <defs>
    <style>{`
      .cls-1 {
        fill: #ca2026;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #365fab;
      }

      .cls-3 {
        fill: #000;
      }

      .cls-4 {
        fill: #591f5f;
      }

      .cls-5 {
        fill: #f9ab60;
      }

      .cls-6 {
        fill: #452e8f;
      }

      .cls-7 {
        fill: #ee2a24;
      }

      .cls-12, .cls-13 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 26px;
      }

      .cls-8 {
        fill: #58b6e7;
      }

      .cls-9 {
        fill: #43b4e7;
      }

      .cls-10 {
        fill: #ffdda6;
      }

      .cls-11 {
        fill: #94221e;
      }

      .cls-13 {
        stroke-linecap: round;
      }
    `}</style>
  </defs>
  <rect className="cls-7" x="275.37" y="602.49" width="374.88" height="390.07"/>
  <rect className="cls-11" x="650.25" y="604.75" width="181.83" height="387.81"/>
  <path className="cls-3" d="M633.58,623.55v355.23h-340.4v-355.23h340.4M659.58,597.55h-392.4v407.23h392.4v-407.23h0Z"/>
  <path className="cls-3" d="M813.06,623.55v355.23h-155.46v-355.23h155.46M839.06,597.55h-207.46v407.23h207.46v-407.23h0Z"/>
  <path className="cls-6" d="M737.82,480.13l-14.59,58.54c-1.8,7.21,2.59,14.5,9.79,16.28,7.2,1.78,14.5-2.61,16.29-9.82l14.59-58.54c1.8-7.21-2.59-14.5-9.79-16.28-7.2-1.78-14.5,2.61-16.29,9.82Z"/>
  <path className="cls-4" d="M135.29,117.23l-29.66-29.67c-5.25-5.25-13.77-5.26-19.02-.02-5.26,5.24-5.27,13.74-.02,18.99l29.66,29.67c5.25,5.25,13.77,5.26,19.02.02,5.26-5.24,5.27-13.74.02-18.99Z"/>
  <path className="cls-4" d="M937.14,700.05l61.35,47.7c5.86,4.56,14.31,3.51,18.88-2.34,4.56-5.85,3.51-14.29-2.35-18.85l-61.35-47.7c-5.86-4.56-14.32-3.51-18.88,2.34-4.57,5.85-3.51,14.29,2.35,18.85Z"/>
  <path className="cls-1" d="M651.28,463.48l-16.66-38.48c-2.95-6.81-10.86-9.93-17.67-6.97-6.81,2.96-9.94,10.88-6.99,17.69l16.67,38.48c2.95,6.81,10.86,9.93,17.67,6.97,6.81-2.96,9.94-10.88,6.99-17.69Z"/>
  <path className="cls-6" d="M133.89,519.92l47.79-47.61c5.26-5.24,5.27-13.74.02-18.99-5.25-5.25-13.76-5.26-19.02-.02l-47.79,47.62c-5.26,5.24-5.27,13.74-.02,18.99,5.25,5.25,13.76,5.26,19.02.02Z"/>
  <path className="cls-2" d="M194.37,113.27c7.83,0,14.17-6.34,14.17-14.15s-6.35-14.15-14.17-14.15-14.17,6.34-14.17,14.15,6.35,14.15,14.17,14.15Z"/>
  <path className="cls-2" d="M277,416.66c7.83,0,14.17-6.33,14.17-14.15s-6.35-14.15-14.17-14.15-14.17,6.34-14.17,14.15,6.35,14.15,14.17,14.15Z"/>
  <path className="cls-1" d="M335.11,321.76c7.47,2.34,15.42-1.8,17.76-9.26,2.34-7.46-1.82-15.4-9.29-17.74s-15.42,1.81-17.76,9.26c-2.34,7.46,1.82,15.4,9.29,17.74Z"/>
  <path className="cls-9" d="M331.93,560.74c7.83,0,14.18-6.34,14.18-14.15s-6.35-14.15-14.18-14.15-14.17,6.34-14.17,14.15,6.35,14.15,14.17,14.15Z"/>
  <path className="cls-1" d="M171.05,633.13c7.83,0,14.17-6.33,14.17-14.15s-6.35-14.15-14.17-14.15-14.17,6.34-14.17,14.15,6.35,14.15,14.17,14.15Z"/>
  <path className="cls-4" d="M569.5,555.36c7.83,0,14.17-6.34,14.17-14.15s-6.35-14.15-14.17-14.15-14.17,6.34-14.17,14.15,6.35,14.15,14.17,14.15Z"/>
  <path className="cls-6" d="M272.76,269.92c7.47,2.34,15.42-1.81,17.76-9.26s-1.82-15.4-9.29-17.74c-7.47-2.34-15.42,1.81-17.76,9.26-2.34,7.46,1.82,15.4,9.29,17.74Z"/>
  <path className="cls-4" d="M570.02,346.8c7.47,2.34,15.42-1.81,17.76-9.26,2.34-7.46-1.82-15.4-9.29-17.74-7.47-2.34-15.42,1.81-17.76,9.26-2.34,7.46,1.82,15.4,9.29,17.74Z"/>
  <path className="cls-1" d="M794.25,102c-5.46,5.61-5.36,14.57.24,20.03s14.56,5.33,20.03-.27c5.46-5.61,5.36-14.57-.24-20.03-5.6-5.45-14.56-5.33-20.03.27Z"/>
  <path className="cls-9" d="M1005.98,634.6c7.83,0,14.17-6.34,14.17-14.15s-6.35-14.15-14.17-14.15-14.18,6.33-14.18,14.15,6.35,14.15,14.18,14.15Z"/>
  <path className="cls-2" d="M900.04,648.75c7.83,0,14.17-6.33,14.17-14.15s-6.35-14.15-14.17-14.15-14.17,6.34-14.17,14.15,6.35,14.15,14.17,14.15Z"/>
  <g>
    <path className="cls-10" d="M137.08,388.09c-33.01,0-63.17-18.07-78.71-47.16-23.16-43.35-6.73-97.46,36.62-120.61,12.9-6.89,27.39-10.53,41.91-10.53,33.01,0,63.17,18.07,78.71,47.16,11.22,21,13.59,45.11,6.67,67.89-6.92,22.78-22.29,41.5-43.29,52.72-12.9,6.89-27.39,10.53-41.91,10.53Z"/>
    <path className="cls-5" d="M136.91,222.79c28.2,0,53.96,15.43,67.23,40.28,19.78,37.03,5.75,83.24-31.28,103.02-11.18,5.97-23.22,9-35.78,9-28.2,0-53.97-15.43-67.24-40.28-9.58-17.94-11.6-38.53-5.7-57.99,5.91-19.46,19.04-35.45,36.98-45.03,11.18-5.97,23.22-9,35.78-9h0M136.91,196.79c-16.23,0-32.71,3.88-48.03,12.07-49.75,26.58-68.54,88.45-41.96,138.2,18.39,34.43,53.69,54.03,90.17,54.03,16.23,0,32.71-3.88,48.03-12.07,49.75-26.58,68.54-88.45,41.96-138.2-18.39-34.43-53.69-54.03-90.17-54.03h0Z"/>
  </g>
  <path className="cls-5" d="M102.12,290.09l46.93-25.07c7.27-3.89,16.41-1.11,20.29,6.16l15.78,29.54-13.4,7.16-15.69-29.37-46.58,24.88,15.69,29.37-13.4,7.16-15.77-29.53c-3.89-7.28-1.11-16.42,6.17-20.3Z"/>
  <g>
    <path className="cls-10" d="M889.64,566.36c-10.26,0-20.41-1.78-30.17-5.29-46.24-16.64-70.33-67.8-53.69-114.04,12.69-35.28,46.4-58.98,83.87-58.98,10.26,0,20.41,1.78,30.17,5.29,46.24,16.64,70.33,67.8,53.69,114.04-12.69,35.28-46.4,58.98-83.87,58.98Z"/>
    <path className="cls-5" d="M889.66,375.05v26c8.75,0,17.42,1.52,25.76,4.52,19.13,6.88,34.44,20.81,43.1,39.21,8.66,18.4,9.64,39.07,2.75,58.2-10.84,30.14-39.63,50.38-71.64,50.38-8.76,0-17.43-1.52-25.77-4.52-19.13-6.89-34.44-20.81-43.1-39.21-8.66-18.4-9.64-39.07-2.75-58.2,10.84-30.13,39.63-50.38,71.64-50.38v-26M889.65,375.05c-41.85,0-81.12,25.93-96.1,67.58-19.1,53.07,8.45,111.58,61.52,130.68,11.43,4.11,23.09,6.06,34.57,6.06,41.85,0,81.12-25.93,96.1-67.58,19.1-53.07-8.45-111.58-61.52-130.68-11.43-4.11-23.09-6.06-34.57-6.06h0Z"/>
  </g>
  <path className="cls-5" d="M872.83,445.39l50.06,18.01c7.76,2.79,11.82,11.43,9.03,19.19l-11.34,31.51-14.3-5.14,11.27-31.33-49.69-17.88-11.27,31.33-14.3-5.14,11.34-31.5c2.79-7.76,11.43-11.83,19.2-9.04Z"/>
  <g>
    <path className="cls-10" d="M443.24,520.96c-10.02,0-19.94-1.7-29.5-5.05-46.38-16.26-70.88-67.23-54.61-113.61,12.51-35.69,46.31-59.66,84.11-59.66,10.02,0,19.94,1.7,29.5,5.05,46.38,16.26,70.88,67.23,54.61,113.61-12.51,35.69-46.32,59.66-84.11,59.66Z"/>
    <path className="cls-5" d="M443.24,329.64v26c8.55,0,17.03,1.45,25.19,4.32,19.19,6.73,34.61,20.53,43.42,38.86,8.81,18.33,9.96,38.99,3.23,58.18-10.69,30.48-39.56,50.96-71.84,50.96-8.55,0-17.03-1.45-25.2-4.32-39.61-13.89-60.54-57.42-46.65-97.04,10.69-30.48,39.56-50.96,71.84-50.96v-26M443.24,329.64c-42.17,0-81.63,26.32-96.37,68.36-18.67,53.23,9.35,111.51,62.58,130.17,11.18,3.92,22.59,5.78,33.8,5.78,42.17,0,81.63-26.32,96.37-68.36,18.67-53.23-9.35-111.51-62.58-130.17-11.18-3.92-22.59-5.78-33.8-5.78h0Z"/>
  </g>
  <path className="cls-5" d="M426.17,400.12l50.2,17.61c7.78,2.73,11.92,11.33,9.19,19.11l-11.08,31.6-14.34-5.03,11.02-31.42-49.84-17.48-11.02,31.42-14.34-5.03,11.08-31.59c2.73-7.79,11.34-11.92,19.12-9.19Z"/>
  <rect className="cls-11" x="742.32" y="167.63" width="100.62" height="180.19" transform="translate(1192.71 -473.44) rotate(100.67)"/>
  <rect className="cls-7" x="252.78" y="147.29" width="441.66" height="100.62" transform="translate(44.8 -84.31) rotate(10.67)"/>
  <path className="cls-3" d="M281.03,127.4l418.47,78.88-13.03,69.15-418.47-78.88,13.03-69.15M260.3,97.03l-22.67,120.25,469.57,88.51,22.67-120.25-469.57-88.51h0Z"/>
  <path className="cls-3" d="M722.33,210.58l152.77,28.8-13.03,69.15-152.77-28.8,13.03-69.15M701.6,180.22l-22.67,120.25,203.87,38.43,22.67-120.25-203.87-38.43h0Z"/>
  <path className="cls-1" d="M1019.19,231.72l20.39-36.64c3.61-6.49,1.27-14.66-5.22-18.26-6.49-3.6-14.68-1.27-18.29,5.22l-20.39,36.64c-3.61,6.48-1.27,14.66,5.22,18.26,6.49,3.6,14.68,1.27,18.29-5.22Z"/>
  <path className="cls-4" d="M956.51,312.64c7.83,0,14.17-6.34,14.17-14.15s-6.35-14.15-14.17-14.15-14.17,6.34-14.17,14.15,6.35,14.15,14.17,14.15Z"/>
  <path className="cls-6" d="M911.42,170.07l56.06-37.53c6.17-4.13,7.81-12.48,3.67-18.64-4.14-6.16-12.5-7.81-18.67-3.68l-56.06,37.54c-6.17,4.13-7.81,12.47-3.67,18.63,4.14,6.16,12.5,7.81,18.67,3.68Z"/>
  <path className="cls-9" d="M722.83,386.63c7.83,0,14.18-6.34,14.18-14.15s-6.35-14.15-14.18-14.15-14.17,6.34-14.17,14.15,6.35,14.15,14.17,14.15Z"/>
  <path className="cls-8" d="M612.07,81.02l-26.32-32.67c-4.66-5.78-13.13-6.7-18.91-2.05-5.79,4.65-6.7,13.1-2.05,18.88l26.32,32.67c4.66,5.78,13.13,6.7,18.91,2.05,5.79-4.65,6.7-13.1,2.05-18.88Z"/>
  <path className="cls-6" d="M681.22,135.61c7.47,2.34,15.42-1.81,17.76-9.26,2.34-7.46-1.82-15.4-9.29-17.74-7.47-2.34-15.42,1.81-17.76,9.26-2.34,7.46,1.82,15.4,9.29,17.74Z"/>
  <path className="cls-4" d="M234.91,537.34c7.47,2.34,15.42-1.81,17.76-9.26,2.34-7.46-1.82-15.4-9.29-17.74s-15.42,1.81-17.76,9.26c-2.34,7.46,1.82,15.4,9.29,17.74Z"/>
  <line className="cls-12" x1="464.08" y1="604.72" x2="464.08" y2="992.56"/>
  <line className="cls-12" x1="481.53" y1="256.04" x2="501.28" y2="151.26"/>
  <line className="cls-12" x1="737" y1="597.55" x2="737" y2="985.39"/>
  <line className="cls-12" x1="786.4" y1="315.13" x2="806.15" y2="210.35"/>
  <path className="cls-13" d="M765.84,72.23c-5.71,16.22-23.55,24.78-39.78,19.07l-27.18-9.56c-23.74-8.35-49.85,4.17-58.2,27.91l-9.56,27.18c-9.19,26.13-36.35,33.03-63.99,30.9"/>
  <path className="cls-13" d="M555.33,165.89c-19.07-6.23-36.01-16.8-37.73-33.91l-2.88-28.67c-2.52-25.04-24.94-43.36-49.98-40.84l-28.67,2.88c-27.56,2.77-52.23-17.31-55.15-44.82"/>
</svg>
    );
}

export default IconERGift;
