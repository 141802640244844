import { createContext, useState, useContext, useRef } from 'react';
import useInterval from 'src/hooks/useInterval';
import { userInfoAutoRefreshInterval } from '../constants';

interface Icaptcha {
    email: string;
    key: string;
    captcha: string;
}

interface GuestModal {
    open: boolean;
    closeBehavior?: 'go-to-cart' | 'default' | 'onboarding';
    initialScreen?: string;
    guestCheckout?: boolean;
    previousRoute?: string;
}

const UserUpdateContext = createContext({
    userUpdateCount: 0,
    userListUpdateCount: 0,
    userFavoriteUpdateCount: 0,
    featuredListUpdateCount: 0,
    digitalCoupons: 0,
    walletRewardsTab: false,
    isStoreVisited: false,
    isRecyclePopUpShowed: false,
    walletStaplesRewardsTab: false,
    isScannbleDeepLink: false,
    walletRewardsTabWithoutRewardsScreen: false,
    isNotifyChooseCategory: false,
    loginCaptchaRes: {
        email: '',
        key: '',
        captcha: '',
    },
    navBottomActiveTab: '',
    isPointHistoryDrawerOpen: false,
    guestModal: { open: false } as GuestModal,
    hideFooter: false,

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setNavBottomActiveTab: (tabName: string) => { },

    // userGetIdentity: {query_id: "",
    //   distinct_id: "",
    //   search_id: "",
    //   session_id: ""},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    notifyUserUpdate: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    notifyUserListUpdate: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    notifyUserFavoriteUpdate: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    notifyFeaturedListUpdate: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    classroomWalletRewardsTab: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    notifyDigitalCouponsUpdate: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    // updateUserGetIdentity: (data: any) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    storeVisitedUpdated: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    RecyclePopUpUpdate: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    staplesRewardsWalletTab: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    staplesScannableCouponDeepLink: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    classroomWalletRewardsTabWithoutRewardsScreen: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLoginCaptchaResData: (data: Icaptcha) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    openChooseCategory: (bool: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setPointHistoryDrawer: (bool: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setGuestModal: (bool: GuestModal) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setHideFooter: (isHideFooter: boolean) => { },
});

export function useUserUpdateContextState() {
    const lastUserUpdateTimeRef = useRef(0);
    const [userUpdateCount, setUserUpdateCount] = useState(0);
    const [isRecyclePopUpShowed, setRecyclePopUpShowed] = useState(false);
    const [isNotifyChooseCategory, setNotifyChooseCategory] = useState(false);
    const [isPointHistoryDrawerOpen, setPointHistoryDrawer] = useState(false);
    const [guestModal, setGuestModal] = useState<GuestModal>({
        open: false,
    });
    const [hideFooter, setHideFooter] = useState(false);

    const [navBottomActiveTab, setNavBottomActiveTab] = useState('');

    function openChooseCategory(flag: boolean) {
        setNotifyChooseCategory(flag);
    }

    function notifyUserUpdate() {
        lastUserUpdateTimeRef.current = new Date().getTime();
        setUserUpdateCount((prevVal) => prevVal + 1);
    }
    const [userFavoriteUpdateCount, setUserFavoriteUpdateCount] = useState(0);
    function notifyUserFavoriteUpdate() {
        setUserFavoriteUpdateCount((prevVal) => prevVal + 1);
    }
    const [userListUpdateCount, setUserListUpdateCount] = useState(0);
    function notifyUserListUpdate() {
        setUserListUpdateCount((prevVal) => prevVal + 1);
    }

    const [featuredListUpdateCount, seFeaturedListUpdateCount] = useState(0);
    const [digitalCoupons, setDigitalCoupons] = useState(0);
    function notifyFeaturedListUpdate() {
        seFeaturedListUpdateCount((prevVal) => prevVal + 1);
    }
    function notifyDigitalCouponsUpdate() {
        setDigitalCoupons((prevVal) => prevVal + 1);
    }
    const [walletRewardsTab, setWalletRewardsTab] = useState(false);
    const [
        walletRewardsTabWithoutRewardsScreen,
        setWalletRewardsTabWithoutRewardsScreen,
    ] = useState(false);
    const [walletStaplesRewardsTab, setWalletStaplesRewardsTab] =
        useState(false);
    const [isScannbleDeepLink, setScannableDeepLink] = useState(false);
    const [isStoreVisited, setStoreVisited] = useState(false);
    const [loginCaptchaRes, setLoginCaptchaRes] = useState({
        email: '',
        key: '',
        captcha: '',
    });

    function classroomWalletRewardsTab() {
        setWalletRewardsTab(!walletRewardsTab);
    }

    function classroomWalletRewardsTabWithoutRewardsScreen() {
        setWalletRewardsTabWithoutRewardsScreen(
            !walletRewardsTabWithoutRewardsScreen
        );
    }
    function staplesRewardsWalletTab() {
        setWalletStaplesRewardsTab(!walletStaplesRewardsTab);
    }

    function staplesScannableCouponDeepLink() {
        setScannableDeepLink(!isScannbleDeepLink);
    }

    const storeVisitedUpdated = () => {
        setStoreVisited(!isStoreVisited);
    };

    const RecyclePopUpUpdate = () => {
        setRecyclePopUpShowed(!isRecyclePopUpShowed);
    };
    function setLoginCaptchaResData(data: Icaptcha) {
        setLoginCaptchaRes(data);
    }
    // const [userGetIdentity, setUserGetIdentity] = useState({query_id: "",
    // distinct_id: "",
    // search_id: "",
    // session_id: ""});
    // function updateUserGetIdentity(data: any) {
    //   setUserGetIdentity(data.data);
    // }
    useInterval(() => {
        // If anywhere else in the app has called notifyUserUpdate within the last
        // (userInfoAutoRefreshInterval / 2) millseconds then skip the auto update so we aren't making extra calls
        if (
            new Date().getTime() - userInfoAutoRefreshInterval / 2 >
            lastUserUpdateTimeRef.current
        ) {
            notifyUserUpdate();
        }
    }, userInfoAutoRefreshInterval);

    return {
        userUpdateCount,
        userListUpdateCount,
        userFavoriteUpdateCount,
        featuredListUpdateCount,
        walletRewardsTab,
        digitalCoupons,
        isStoreVisited,
        isRecyclePopUpShowed,
        walletStaplesRewardsTab,
        isScannbleDeepLink,
        walletRewardsTabWithoutRewardsScreen,
        loginCaptchaRes,
        navBottomActiveTab,
        isNotifyChooseCategory,
        isPointHistoryDrawerOpen,
        guestModal,
        hideFooter,
        setHideFooter,
        // userGetIdentity,
        notifyUserUpdate,
        notifyUserListUpdate,
        notifyUserFavoriteUpdate,
        notifyFeaturedListUpdate,
        classroomWalletRewardsTab,
        notifyDigitalCouponsUpdate,
        storeVisitedUpdated,
        RecyclePopUpUpdate,
        staplesRewardsWalletTab,
        staplesScannableCouponDeepLink,
        classroomWalletRewardsTabWithoutRewardsScreen,
        setLoginCaptchaResData,
        // updateUserGetIdentity,
        setNavBottomActiveTab,
        openChooseCategory,
        setPointHistoryDrawer,
        setGuestModal,
    };
}

export function useUserUpdateContext() {
    return useContext(UserUpdateContext);
}

export default UserUpdateContext;
