/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import NavTop from 'src/components/nav-top';
import { Box } from '@material-ui/core';
import FullWidthCard from 'src/components/cards/full-width-card';
import { useFormik } from 'formik';
import ValidationTextField from 'src/components/validation-text-field';
import LoadingButton from 'src/components/loading-button';
import { lookupUserEmail } from 'src/services/userServices';
import {
    eventMetricsMap,
    useEventsReporter,
    useGuestMode,
    useRouter,
    useUserState,
} from 'src/hooks';
import { Body, Heading } from 'src/components/UI';
import { IconStaplesLogo } from 'src/components/Icons';
import { customDomains } from 'src/lib/constants';
import { LOGIN_ROUTES } from 'src/routers/routes';
import { validateEmail } from 'src/utils/userUtils';
import { navigateToCheckoutCheck } from 'src/lib/api-client/cart-api';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import styles from './styles.module.scss';
import ShopAsGuest from './ShopAsGuest';

function AccountLookupScreen({ showInModal, setNextScreen }: any) {
    const { push } = useRouter();
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const [showList, setShowList] = useState(false);
    const [query, setQuery] = useState('');
    const initialRef: any = null;
    const emailAutoCompleteRef = useRef(initialRef);
    const [showError, setErrorMsg] = useState(false);
    const [userState, setUserState] = useUserState();
    const { activeIframeId } = useCartCountContext();
    const { guestModal, setGuestModal } = useUserUpdateContext();
    const { isGuestModeOn } = useGuestMode();

    const emailRegx = /[A-Z0-9a-z*._%+-]+@[A-Za-z0-9*.-]+\\.[A-Za-z]{2,}$/;
    const { mutate, isLoading: isLookingUpEmail } = lookupUserEmail({
        onSuccess: (data, email) => {
            if (data.isexisted) {
                setUserState({
                    email,
                });
                if (showInModal) {
                    setNextScreen(LOGIN_ROUTES.login);
                } else {
                    push(LOGIN_ROUTES.login);
                }
            } else if (validateEmail(email)) {
                setUserState({
                    email,
                });
                if (showInModal) {
                    setNextScreen(LOGIN_ROUTES.create);
                } else {
                    push(LOGIN_ROUTES.create);
                }
            } else {
                setErrorMsg(true);
            }
        },
    });

    useEffect(() => {
        analyticsScreenCustomNames({
            logged_in: false,
        });
    }, []);

    const validationSchemaEmail = yup.object({
        email: yup.string().required('This field is required'),
    });

    const formikEmail = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchemaEmail,
        onSubmit: (values) => {
            setErrorMsg(false);
            mutate(values.email);
        },
    });
    const emailInputHasError = Boolean(formikEmail.errors.email);
    const clearError = (e: any) => {
        setErrorMsg(false);
        const fieldName = e.type === 'focus' ? e.target.name : '';
        formikEmail.setFieldError(fieldName, '');
    };

    const handleContinueBtn = (e: any) => {
        e.preventDefault();
        formikEmail.handleSubmit();
        if (guestModal?.guestCheckout) {
            analyticsCustomEvent(eventMetricsMap.login_search, {
                click_text: 'continue',
                element_location: `guest mode checkout`,
            });
        } else {
            if (!guestModal?.open) {
                analyticsCustomEvent(eventMetricsMap.sign_in_page, {
                    click_text: 'continue',
                    element_location: `let's see if you have an account`,
                });
            }
            if (guestModal?.open) {
                analyticsCustomEvent(eventMetricsMap.login_search, {
                    click_text: 'continue',
                    element_location: `let's see if you have an account`,
                });
            }
        }
    };

    const handleSuggetion = (val: any) => {
        if (val?.includes('@')) {
            setShowList(true);
            const index = val.indexOf('@');
            const domainName = val.substring(index);
            setQuery(domainName);
            /*  if (val.includes('.')) {
                 setShowList(false);
             } */
        } else {
            setShowList(false);
        }
    };

    const emailList = (
        <ul className={styles.emailAutoComplete}>
            {customDomains
                .filter((email) =>
                    email.toLowerCase().includes(query.toLowerCase())
                )
                .map((email) => (
                    // eslint-disable-next-line
                    <li
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="link"
                        className={styles.emailItem}
                        onClick={() => {
                            const emailFieldVal = formikEmail.values.email;
                            const index = emailFieldVal.indexOf('@');
                            const clearedEmail = emailFieldVal.substring(
                                0,
                                index
                            );
                            // eslint-disable-next-line
                            formikEmail.values.email = clearedEmail + email;
                            setShowList(false);
                        }}
                    >
                        {email}
                    </li>
                ))}
        </ul>
    );

    const handleCheckoutAsGuest = async (e: any) => {
        e.preventDefault();
        analyticsCustomEvent(eventMetricsMap.app_guest_checkout, {
            click_text: 'checkout as guest',
            element_location: `guest mode checkout`,
        });
        await navigateToCheckoutCheck(isGuestModeOn);
        setGuestModal({ ...guestModal, guestCheckout: false, open: false });
        const checkoutUrl = `${process.env.REACT_APP_DOT_COM_URL}/cc/sparq/cart`;
        const documentData: any = document?.getElementById?.(activeIframeId);

        if (documentData?.contentWindow?.location?.href) {
            documentData.contentWindow.location.href = checkoutUrl;
        }
    };

    return (
        <Box
            className={styles.screen}
            style={{
                justifyContent: 'flex-start',
                height: showInModal ? '85vh' : 'auto',
            }}
        >
            <NavTop
                minHeight="17rem"
                bgImageSrc={require('../../assets/bgs/loginBg.svg')}
                id="login-screen"
            >
                <div className={styles.headerLogoWrapper}>
                    <IconStaplesLogo />
                    <Heading
                        type="h3"
                        size={26}
                        weight="bold"
                        className={styles.headerSubTitle}
                    >
                        Ready to explore
                    </Heading>
                </div>
            </NavTop>
            <FullWidthCard
                cardContainerClassName={styles.verifyContainer}
                noPadding
                id="user-found-screen"
            >
                <div className={styles.confirmContainer}>
                    <Body size={18} weight="bold">
                        {guestModal?.guestCheckout
                            ? 'Enter login info to checkout'
                            : "Let's see if you have an account"}
                    </Body>
                    <form
                        onSubmit={handleContinueBtn}
                        className={styles.lookupForm}
                        id="emailForm"
                    >
                        <ValidationTextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email / Username"
                            classes={{
                                root: clsx(
                                    styles.textFieldLabel,
                                    emailInputHasError &&
                                    styles.textFieldLabelError
                                ),
                            }}
                            inputProps={{
                                'data-testid': 'emailInputBox',
                            }}
                            value={formikEmail.values.email}
                            onChange={(e) => {
                                formikEmail.handleChange(e);
                                emailAutoCompleteRef?.current?.change(e);
                                handleSuggetion(e.target.value);
                            }}
                            onFocus={(e) => clearError(e)}
                            error={emailInputHasError}
                            helperText={formikEmail.errors.email}
                        />
                        {showList && emailList}
                        {showError && (
                            <Body color="textRed" size={12} weight="light">
                                Incorrect username, please try again. If you’re
                                a new customer, please enter an email address.
                            </Body>
                        )}
                        <LoadingButton
                            fullWidth
                            disabled={formikEmail.values.email === ''}
                            id="step2-next-button"
                            variant="contained"
                            classes={{
                                root: clsx(styles.loginButton),
                            }}
                            type="submit"
                            loading={isLookingUpEmail}
                        // disabled={(errors && Object.keys(errors).length !== 0) || !isTearms}
                        >
                            Continue
                        </LoadingButton>
                    </form>
                    {guestModal?.guestCheckout ? (
                        <LoadingButton
                            fullWidth
                            id="Checkout-as-guest-button"
                            variant="contained"
                            classes={{
                                root: clsx(styles.checkoutGuestButton),
                            }}
                            loading={false}
                            onClick={handleCheckoutAsGuest}
                        // disabled={(errors && Object.keys(errors).length !== 0) || !isTearms}
                        >
                            Checkout as guest
                        </LoadingButton>
                    ) : null}
                    {!showInModal && <ShopAsGuest referrer="Account look up" />}
                </div>
            </FullWidthCard>
        </Box>
    );
}

export default AccountLookupScreen;
