/* eslint import/namespace: [2, { allowComputed: false }] */
import axios from 'axios';
import { SessionManager, COMMON_HEADERS } from './sessionManager';
import { getSubscriptionKey } from './sign-on';




export const getCmsShopDepartmentList = async (
  id: string,
  isGuestModeOn = false
): Promise<any | null> => {
  let subscriptionKey = "";
  if (isGuestModeOn) {
    subscriptionKey = await getSubscriptionKey('StaplesGuestToken');
  } else {
    subscriptionKey = await getSubscriptionKey('StaplesCMS');
  }
  const url = `${process.env.REACT_APP_EASY_API_CMS_List}`;
  const data = { "tenantId": "STAPLES_DOT_COM", "channel": "sdc", "channelId": "sdc", "contentIds": [id] }

  try {
    const details = await axios.post(url, data, {
      headers: {
        ...COMMON_HEADERS,
        client_id: subscriptionKey,
        Authorization: `Bearer ${SessionManager.access_token}`,
        'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    });
    console.log({ details });
    if (details?.data) {
      return details?.data;
    }
    return null;
  } catch (e) {
    return e;
  }
};

export const getCmsShopDepartmentData = async (
  data: any,
  isGuestModeOn = false
): Promise<any | null> => {
  let subscriptionKey = "";
  if (isGuestModeOn) {
    subscriptionKey = await getSubscriptionKey('StaplesGuestToken');
  } else {
    subscriptionKey = await getSubscriptionKey('StaplesCMS');
  }

  const url = `${process.env.REACT_APP_EASY_API_CMS}`;
  try {
    const reportData = await axios.post(url, data, {
      headers: {
        ...COMMON_HEADERS,
        client_id: subscriptionKey,
        Authorization: `Bearer ${SessionManager.access_token}`,
        'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    });
    console.log({ reportData });
    if (reportData.data) {
      const getTopNavigationOptionsLeft =
        reportData.data?.topNavigationOptionsLeft;
      const findMegaMenuId = getTopNavigationOptionsLeft?.find(
        (menuData: any) => menuData.componentName === 'NavigationMegaMenu'
      )?.metadata?.id;
      console.log("🚀 ~ findMegaMenuId:", findMegaMenuId)

      const findMoreDataDependOnId = await getCmsShopDepartmentList(findMegaMenuId, isGuestModeOn)
      console.log("🚀 ~ findMoreDataDependOnId:", findMoreDataDependOnId)

      const filterOutMobileOnlyData =
        findMoreDataDependOnId?.[0]?.navigationFlyouts?.reduce(
          (acc: any, mData: any) => {
            console.log("🚀 ~ mData:", mData)
            const mobileOnlyData = mData?.navigationFlyoutsItems?.filter(
              (items: any) =>
                items?.permissionlimit?.includes('MobileOnly') ||
                !items?.permissionlimit
            );
            acc = [...acc, ...mobileOnlyData];
            return acc;
          },
          []
        );

      console.log({
        filterOutMobileOnlyData,
      });
      return {
        filterOutMobileOnlyData,
      };
    }
    return null;
  } catch (e) {
    return e;
  }
};
