import React, { ReactNode } from 'react';
import { useScreenSize } from 'src/hooks';
import { mergeClasses } from 'src/stylesheets/utils';
import styles from './styles.module.scss';

interface HeadingProps {
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    children: ReactNode;
    color?: string;
    size?: number;
    lineHeight?: number;
    weight?: 'light' | 'normal' | 'bold';
    textAlign?: 'textLeft' | 'textCenter' | 'textRight';
    className?: string;
    breakpoint?: number;
    tabIndex?: number;
    ariaLabel?: string;
    ariaDescription?: string;
    role?: string;
    ariaHidden?: boolean;
    id?: string;
}

function Heading({
    type,
    children,
    color = 'default',
    size = 16,
    lineHeight,
    weight = 'normal',
    textAlign = 'textLeft',
    className,
    breakpoint = 390,
    tabIndex = undefined,
    ariaLabel = '',
    ariaDescription = '',
    role = undefined,
    ariaHidden = false,
    id='',
}: HeadingProps) {
    const computedClassName = mergeClasses(
        styles.reset,
        styles[color],
        styles[weight],
        styles[textAlign],
        className
    );

    let fontSize = size;
    const lineHeightInPx = `${lineHeight}px`;
    const { width } = useScreenSize();
    if (width < breakpoint) {
        fontSize -= 2;
    }

    switch (type) {
        case 'h1':
            return (
                <h1
                    className={computedClassName}
                    style={{ fontSize, lineHeight: lineHeightInPx }}
                    tabIndex={tabIndex}
                >
                    {children}
                </h1>
            );
        case 'h2':
            return (
                <h2
                    className={computedClassName}
                    aria-label={ariaLabel}
                    aria-hidden={ariaHidden}
                    style={{ fontSize, lineHeight: lineHeightInPx, color }}
                >
                    {children}
                </h2>
            );
        case 'h3':
            return (
                <h3
                    className={computedClassName}
                    aria-label={ariaLabel}
                    style={{ fontSize, lineHeight: lineHeightInPx }}
                    tabIndex={tabIndex}
                    id={id}
                >
                    {children}
                </h3>
            );
        case 'h4':
            return (
                <h4
                    className={computedClassName}
                    aria-label={ariaLabel}
                    style={{ fontSize, lineHeight: lineHeightInPx }}
                >
                    {children}
                </h4>
            );
        case 'h5':
            return (
                <h5
                    className={computedClassName}
                    style={{ fontSize, lineHeight: lineHeightInPx }}
                >
                    {children}
                </h5>
            );
        case 'h6':
            return (
                <h6
                    className={computedClassName}
                    style={{ fontSize, lineHeight: lineHeightInPx }}
                    tabIndex={tabIndex}
                    aria-label={ariaLabel}
                    aria-roledescription={ariaDescription}
                    role={role}
                >
                    {children}
                </h6>
            );
        default:
            return null;
    }
}

export default Heading;
